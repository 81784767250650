export function toTitleCase(value) {
  return value
    .split(' ')
    .map(p => `${p[0].toUpperCase()}${p.slice(1).toLowerCase()}`)
    .join(' ')
}

export function generateVaccinationNumber(state) {
  const prefix = state?.length > 1 ? state : randomString()
  const datePart = new Date().getTime().toString().slice(5, 13)
  return `NG-${prefix}${datePart}${randomString()}`
}

export function randomString(length = 2) {
  const letters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'
  return Array(length)
    .fill('')
    .map(_ => letters.charAt(Math.floor(Math.random() * letters.length)))
    .join('')
}

export function toBool(value) {
  return ['true', true].includes(value)
}
