import Vue from 'vue'
import Router from 'vue-router'

import {
  CreateRecord,
  CreateRecordRI,
  Dashboard,
  Login,
  OfflineRecord,
  RecentRecord,
  RecentRecordRI,
  Verify
} from './pages'
import store from './store'

Vue.use(Router)

const router = new Router({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      name: 'home',
      component: Dashboard
    },
    {
      path: '/login',
      name: 'login',
      component: Login
    },
    {
      path: '/create',
      name: 'create',
      component: CreateRecord
    },
    {
      path: '/create/ri',
      name: 'create-ri',
      component: CreateRecordRI
    },
    {
      path: '/recent-records',
      name: 'recent',
      component: RecentRecord
    },
    {
      path: '/recent-records/ri',
      name: 'recent-ri',
      component: RecentRecordRI
    },
    {
      path: '/offline-records',
      name: 'pending',
      component: OfflineRecord
    },
    {
      path: '/verify',
      name: 'verify',
      component: Verify
    },
    {
      path: '/*',
      name: '404',
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import(/* webpackChunkName: "not-found" */ './pages/page-not-found.vue')
    }
  ]
})

/* Prevent unauthorized access and visiting Login view when token exists */
router.beforeEach((to, _, next) => {
  if (to.name !== 'login' && !store.state.auth.token) {
    next({ name: 'login' })
  } else if (to.name === 'login' && store.state.auth.token) {
    next({ name: 'home' })
  } else {
    next()
  }
})

export default router
