<template>
  <div class="columns is-desktop is-centered">
    <div class="column is-half-desktop mt-4">
      <div class="profile">
        <b-field
          grouped
          label-position="on-border"
        >
          <b-button
            label="Go Back"
            type="is-dark"
            @click="$emit('go-back', $event)"
          />
        </b-field>

        <pre>
          {{ editMode ? 'Update' : 'Create' }} COVID record. Select State to see Vaccination No.
        </pre>

        <e-select
          label="User Team"
          placeholder="Select Team"
          :disabled="hasUserTeam"
          :error="errors.team"
          :options="teamCategoryChoices()"
          :value="userTeam"
          @input="updateProfile('userTeam', $event)"
        />

        <e-select
          v-show="!editMode"
          v-model="state"
          label="State (select to see vaccination number)"
          placeholder="Select State"
          :error="errors.state"
          :options="stateChoices()"
        />

        <e-input
          v-show="editMode || state?.length"
          disabled
          required
          size="is-medium"
          label="* Vaccination Number"
          placeholder="Vaccination Number"
          :error="errors.vaccinationNumber"
          :value="computedValue.profile.vaccinationNumber"
          @input="updateProfile('vaccinationNumber', $event)"
        />

        <e-input
          label="* Family Name"
          placeholder="Surname / Last Name"
          :error="errors.familyName"
          :value="computedValue.profile.familyName"
          @input="updateProfile('familyName', $event)"
        />

        <e-input
          label="* Other Name"
          placeholder="First & Middle Name"
          :error="errors.otherName"
          :value="computedValue.profile.otherName"
          @input="updateProfile('otherName', $event)"
        />

        <e-input
          label="Residential Address"
          :error="errors.residentialAddress"
          :value="computedValue.profile.residentialAddress"
          @input="updateProfile('residentialAddress', $event)"
        />

        <e-select
          label="Type of ID"
          placeholder="Select ID Type"
          :error="errors.idType"
          :options="identificationChoices()"
          :value="computedValue.profile.idType"
          @input="updateProfile('idType', $event)"
        />

        <e-input
          v-show="computedValue.profile.idType !== IDType.NotAvailable"
          label="Identification Number"
          :error="errors.idNumber"
          :value="computedValue.profile.idNumber"
          @input="updateProfile('idNumber', $event)"
        />

        <e-select
          label="Is Age Estimated"
          placeholder="Yes or No?"
          :error="errors.isAgeEstimated"
          :options="yesNoChoices()"
          :value="computedValue.profile.isAgeEstimated"
          @input="updateProfile('isAgeEstimated', $event)"
        />

        <e-input
          v-show="computedValue.profile.isAgeEstimated"
          label="Age"
          placeholder="Enter Age"
          minlength="2"
          type="number"
          :error="errors.age"
          :value="computedValue.profile.age"
          @input="updateProfile('age', $event)"
        />

        <e-date-picker
          v-show="!computedValue.profile.isAgeEstimated"
          label="Date of Birth"
          placeholder="Birth Date"
          :error="errors.birthDate"
          :value="computedValue.profile.birthDate"
          @input="updateProfile('birthDate', $event)"
        />

        <e-input
          label="Email Address"
          type="email"
          :error="errors.email"
          :value="computedValue.profile.email"
          @input="updateProfile('email', $event)"
        />

        <e-input
          label="* Phone Number"
          placeholder="Phone Number"
          minlength="10"
          type="number"
          :error="errors.phoneNumber"
          :value="computedValue.profile.phoneNumber"
          @input="updateProfile('phoneNumber', $event)"
        />

        <e-input
          v-model="phoneNumber2"
          :error="errors.phoneNumber2"
          label="* Repeat Phone Number"
          placeholder="Repeat Phone Number"
          minlength="10"
          type="number"
        />

        <e-select
          v-model="occupationCategory"
          label="Occupation Category"
          placeholder="Select Occupation Category"
          :options="occupationCategoryChoices()"
        />

        <e-input
          placeholder="Occupation"
          :label="`Specify ${occupationCategory || ''} Occupation`"
          :value="computedValue.profile.occupation"
          @input="updateProfile('occupation', $event)"
        />

        <e-select
          label="Any History of Allergies?"
          placeholder="Yes or No?"
          :options="yesNoChoices()"
          :value="computedValue.profile.hasAllergies"
          @input="updateProfile('hasAllergies', $event)"
        />

        <e-select
          label="* Gender"
          placeholder="Select Gender"
          :error="errors.gender"
          :options="genderChoices()"
          :value="computedValue.profile.gender"
          @input="updateProfile('gender', $event)"
        />

        <e-select
          v-if="computedValue.profile.gender === Gender.Female"
          label="* Pregnancy Status"
          placeholder="Select Status"
          :error="errors.pregnancyStatus"
          :options="pregnancyStatusChoices()"
          :value="computedValue.profile.pregnancyStatus"
          @input="updateProfile('pregnancyStatus', $event)"
        />

        <e-select
          v-show="!editMode"
          v-model="registerVaccination"
          label="Do you want to register vaccination events for this client?"
          placeholder="Yes or No?"
          :options="yesNoChoices()"
        />
      </div>

      <div v-show="editMode || registerVaccination">
        <hr style="border: solid 1px #ccc">

        <e-select
          v-model="computedValue.verification.values.verified"
          label="Verified"
          placeholder="Yes or No?"
          :error="errors.verified"
          :options="yesNoChoices()"
        />

        <e-input
          v-model="qrcode"
          type="text"
          label="* QR Code Link"
          :error="errors.qrcode"
          :placeholder="qrcodePlaceholder"
          :readonly="!qrcodeEditable"
          icon-right="edit"
          icon-right-clickable
          @icon-right-click="qrcodeEditable = !qrcodeEditable"
        />

        <span
          v-show="!qrcode?.length"
          class="has-text-left has-text-danger mb-5"
        >
          Click button below to enable your camera to scan QR code.
        </span>

        <b-button
          expanded
          rounded
          type="is-primary"
          :class="computedValue.verification.values.verified ? '' : 'mb-5'"
          :label="showQRCodeScanner ? 'Hide QR Scanner' : 'Show QR Scanner'"
          @click="showQRCodeScanner = !showQRCodeScanner"
        />

        <b-field
          v-if="showQRCodeScanner"
          label-position="on-border"
          class="mt-5"
        >
          {{ qrcode }}
          <qrcode-stream
            @init="onQrInit"
            @decode="onQrDecode"
          />
        </b-field>

        <div :hidden="!computedValue.verification.values.verified">
          <b-tabs v-model="activeTabIndex">
            <e-vaccination-form
              v-for="(item, index) in computedValue.vaccinations"
              :key="item.key"
              v-model="item.data"
              :label="getVaccinationTabName(item)"
              :disabled="disableVaccinationTab(item, index)"
              :indicate-location="item.indicateLocation"
              :max-vaccination-date="maxVaccinationDate"
              :choices="{
                batches: getVaccineBatches(item),
                vaccines: getVaccineChoices(item),
              }"
            />

            <b-notification
              v-if="computedValue.vaccinations[0].data.values.qrcode && !verifyBarcode"
              type="is-danger"
              aria-close-label="Close notification"
              role="alert"
              class="column"
            >
              QR code does not match pattern
            </b-notification>
          </b-tabs>
        </div>
      </div>

      <b-button
        expanded
        rounded
        size="is-large"
        type="is-success"
        :disabled="disableSubmit"
        :label="`${!editMode ? 'Create': 'Update'} Record`"
        @click="submitForm()"
      />
    </div>
  </div>
</template>

<script>
import cloneDeep from 'lodash/cloneDeep'
import isEmpty from 'lodash/isEmpty'
import { QrcodeStream } from 'vue-qrcode-reader'
import { mapGetters, mapState } from 'vuex'

import { EDatePicker, EInput, ESelect } from '../components/generic'
import { DataElementID } from '../constants/ids'
import { Gender, IDType } from '../constants/options'
import { VaccineDisplayNameMap, VaccineType } from '../constants/vaccine'
import EVaccinationForm from '../containers/covid-vaccination-form'
import QrCodeMixin from '../mixins/qrcode-mixin'
import { CovidRecord } from '../models'
import {
  genderChoices,
  identificationChoices,
  occupationCategoryChoices,
  pregnancyStatusChoices,
  stateChoices,
  teamCategoryChoices,
  vaccineChoices,
  yesNoChoices
} from '../utils/choices'
import { isAgeBelow } from '../utils/date'
import { generateVaccinationNumber, toTitleCase } from '../utils/string'

const MinAgeAllowed = 12
const MinAgeAllowedError =
  `Clients under ${MinAgeAllowed} years old are not eligible for vaccination`

const RequiredFields = [
  'userTeam',
  'familyName',
  'otherName',
  'phoneNumber',
  'phoneNumber2',
  'gender',
  'vaccinationNumber'
]

const VaccinationTabNameMap = {
  DOSE1: 'First Dose',
  DOSE2: 'Second Dose',
  BOOSTER: 'Booster Dose',
  BOOSTER2: 'Second Booster Dose'
}

export default {
  components: {
    EDatePicker,
    EInput,
    ESelect,
    EVaccinationForm,
    QrcodeStream
  },
  mixins: [QrCodeMixin],
  props: {
    data: { type: Object, default: null },
    value: {
      type: Object,
      default() {
        return CovidRecord.new()
      }
    }
  },
  emits: ['go-back', 'input'],
  data() {
    return {
      DataElementID: Object.freeze(DataElementID.COVID),
      Gender: Object.freeze(Gender),
      IDType: Object.freeze(IDType),

      errors: {},
      newValue: this.value,

      activeTabIndex: 0,
      disableSubmit: false,
      editMode: false,
      maxVaccinationDate: new Date(),
      occupationCategory: undefined,
      phoneNumber2: undefined,
      qrcodeEditable: false,
      registerVaccination: false,
      showQRCodeScanner: false,
      state: undefined,
      userTeam: undefined
    }
  },
  computed: {
    ...mapGetters(['getUserTeamOption', 'hasUserTeam']),
    ...mapState(['auth', 'programId']),
    computedValue: {
      get() {
        return this.newValue ? this.newValue : CovidRecord.new()
      },
      set(value) {
        this.newValue = value
        this.$emit('input', value)
      }
    },
    qrcodePlaceholder() {
      return !this.qrcodeEditable
        ? 'Click edit icon to manually Paste QR code link here.'
        : 'Kindly Paste QR code link here'
    }
  },
  watch: {
    state(value) {
      const vaccinationNumber = generateVaccinationNumber(value)
      this.computedValue.profile.vaccinationNumber = vaccinationNumber
    },
    value(value) {
      this.newValue = value
    },
    'computedValue.profile.birthDate': function(newValue) {
      if (isAgeBelow(newValue, MinAgeAllowed)) {
        this.$buefy.toast.open(MinAgeAllowedError)
      }
    }
  },
  mounted() {
    if (!isEmpty(this.data)) {
      this.editMode = true
      this.newValue = new CovidRecord(cloneDeep(this.data))
      this.phoneNumber2 = this.newValue.profile.phoneNumber
      this.qrcode = this.newValue.vaccinations[0].data.values.qrCode
    }

    this.activeTabIndex = 0
    this.userTeam = this.getUserTeamOption
  },
  methods: {
    genderChoices,
    identificationChoices,
    occupationCategoryChoices,
    pregnancyStatusChoices,
    stateChoices,
    teamCategoryChoices,
    toTitleCase,
    vaccineChoices,
    yesNoChoices,

    disableVaccinationTab(item, index) {
      const { verification, vaccinations } = this.computedValue
      if (!verification.values.verified) {
        return true
      }

      if (item.key === 'DOSE1') {
        return !verification.values.verified
      }

      const firstVaccination = vaccinations[0].data
      const lastVaccination = vaccinations[index - 1].data
      if (firstVaccination.values.vaccineType !== VaccineType.Johnson) {
        return !lastVaccination.vaccinationDate || !lastVaccination.values.vaccineType
      }

      switch (item.key) {
        case 'DOSE2':
          return true
        case 'BOOSTER':
          return !firstVaccination.vaccinationDate || !firstVaccination.values.vaccineType
        case 'BOOSTER2':
          return !lastVaccination.vaccinationDate || !lastVaccination.values.vaccineType
      }
    },
    getVaccinationTabName(item) {
      return VaccinationTabNameMap[item.key]
    },
    getVaccineBatches(item) {
      return []
    },
    getVaccineChoices(item) {
      if (['DOSE1', 'DOSE2'].includes(item.key)) {
        return this.vaccineChoices()
      }

      const firstVaccination = this.computedValue.vaccinations[0]
      if (firstVaccination.data.vaccineType !== VaccineType.Johnson) {
        return this.vaccineChoices()
      }

      return [
        VaccineType.Johnson,
        VaccineType.Pfizer
      ].map(id => ([id, VaccineDisplayNameMap[id]]))
    },

    isFormValid() {
      const errors = {}
      const profile = this.computedValue.profile

      const extendedRequiredFields = !this.editMode
        ? ['state', ...RequiredFields]
        : [...RequiredFields]

      if (profile.gender === Gender.Female) extendedRequiredFields.push('pregnancyStatus')
      if (this.registerVaccination) {
        extendedRequiredFields.push('qrcode')
      }

      for (const field of extendedRequiredFields) {
        const value = field in profile
          ? profile[field]
          : this[field]

        if (!value) {
          errors[field] = 'Please fill in this field.'
        }
      }

      // age validation
      if (
        (profile.isAgeEstimated && profile.age < MinAgeAllowed) ||
        (!profile.isAgeEstimated && isAgeBelow(profile.birthDate, MinAgeAllowed))
      ) {
        errors.age = `Age should be at least ${MinAgeAllowed} years old`
      }

      // phone number validation
      if (profile.phoneNumber) {
        if (profile.phoneNumber.length !== 11) {
          errors.phoneNumber = 'Phone number should be 11 digits'
        }
        if (profile.phoneNumber !== this.phoneNumber2) {
          errors.phoneNumber2 = 'Phone numbers do not match'
        }
      }

      if (this.registerVaccination) {
        // verified validation
        if (!this.computedValue.verification.values.verified) {
          errors.verified = 'Please fill in this field.'
        }

        // qrcode validation
        if (!this.qrcode?.includes('verify')) {
          errors.qrcode = 'Invalid QR code value provided'
        }
      }

      // validate first-dose vaccination
      const firstDose = this.computedValue.vaccinations[0].data
      if (!firstDose.modified) {
        errors._message = 'Please fill in the fields for "First Dose".'
      } else {
        const fields = []
        if (!firstDose.vaccinationDate) fields.push('Vaccination Date')
        if (!firstDose.values.vaccineType) fields.push('Vaccine Type')
        if (fields.length) {
          errors._message = `These "First Dose" fields are required: ${fields.join(', ')}`
        }
      }

      // validate other doses
      for (const vaccination of this.computedValue.vaccinations.slice(1)) {
        if (!vaccination.data.modified) continue

        const fields = []
        if (!vaccination.data.vaccinationDate) fields.push('Vaccination Date')
        if (!vaccination.data.values.vaccineType) fields.push('Vaccine Type')
        if (fields.length) {
          const label = VaccinationTabNameMap[vaccination.key]
          errors._message = `These ${label} fields are required: ${fields.join(', ')}`
        }
      }

      this.errors = errors
      if (!isEmpty(errors)) {
        window.scrollTo(0, 0)
        return false
      }
      return true
    },
    submitForm() {
      if (!this.isFormValid()) {
        const { _message, ...rest } = this.errors
        const message = !isEmpty(rest)
          ? 'Required input(s) missing or invalid input(s) provided'
          : _message

        this.$buefy.toast.open(message)
        return
      }

      // update record to add qrcode
      this.computedValue.vaccinations[0].data.values.qrCode = this.qrcode
      this.$emit('submit', new CovidRecord(this.computedValue))
    },

    updateValue(key, value) {
      this.computedValue = { ...this.computedValue, [key]: value }
    },
    updateProfile(key, value) {
      delete this.errors[key]
      this.computedValue.modified = true
      this.updateValue('profile', { ...this.computedValue.profile, [key]: value })
    }
  }
}
</script>
