export default {
  props: {
    customClass: { type: String, default: 'mb-5' },
    error: { type: String, default: '' },
    label: { type: String, default: '' },
    placeholder: {
      type: String,
      default: function() { return this.label }
    }
  }
}
