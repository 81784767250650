<template>
  <b-field
    class="mb-3 has-text-left"
  >
    <b-checkbox
      :disabled="disabled"
      :value="computedValue"
      @input="updateValue($event)"
    >
      {{ label }}
    </b-checkbox>
  </b-field>
</template>

<script>
import FormElementMixin from '../../mixins/component-mixin'

export default {
  name: 'ECheckbox',
  mixins: [FormElementMixin],
  props: {
    disabled: { type: Boolean, default: false },
    value: { type: Boolean, default: false }
  },
  data() {
    return {
      newValue: this.value
    }
  },
  computed: {
    computedValue: {
      get() {
        return this.newValue
      },
      set(value) {
        this.newValue = value
        this.$emit('input', value)
      }
    }
  },
  watch: {
    value(value) {
      this.newValue = value
    }
  },
  methods: {
    updateValue(value) {
      this.computedValue = value
    }
  }
}
</script>
