/**
 * Current application version
 */
export const APP_VERSION = 'v25.09.23'

/**
 * Latest commit hash for the running build
 */
export const APP_REVISION = process.env.VUE_APP_REVISION

/**
 * The full name for the application
 */
export const APP_FULL_NAME = 'EMID Mobile App'

/**
 * The color for the horizontal strip at the top and bottom of the screen.
 */
export const APP_STRIP_COLOR = '#DA6A00' // orange

/**
 * DHIS2 server API URL
 */
export const DHIS_API_URL = process.env.VUE_APP_DHIS_API_URL

/**
 * COVID vaccination start date
 */
export const VACCINATION_START_DATE = new Date('2021-03-01')
