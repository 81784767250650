import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'

import { VACCINATION_START_DATE } from './constants/env'
import { ProgramID, TeamCategoryOptions } from './constants/ids'
import { isCovidRecord, isRIRecord } from './models'

Vue.use(Vuex)

const store = new Vuex.Store({
  plugins: [createPersistedState()],
  state: {
    // Auth credentials -> including token
    auth: {},

    // Program ID (can be gotten from /api/programs/)
    programId: ProgramID.COVID,

    // COVID record read for offline access/usage
    covidRecords: [],
    riRecords: [],

    // records for update and insert/create on DHIS2
    upsertRecords: {},

    // tracks the vaccination number of records that have been successfully
    // synced to DHIS2 but have not be pulled down. These records should not
    // be updated able
    syncedRecords: [],

    // Attribute Keys from DHIS endpoint - use in update
    pageSize: 50,

    // Roll out date
    vaccinationStartDate: VACCINATION_START_DATE
  },
  getters: {
    getOrganizationName: (state) => {
      return state.auth?.user?.displayName?.slice(0, 26) || ''
    },
    getUserTeamName: (state) => {
      return state.auth?.user?.userGroup?.name || ''
    },
    getUserTeamOption: (state, getters) => {
      return TeamCategoryOptions
        .find(option => option.label === getters.getUserTeamName)
        ?.value
    },
    hasUserTeam: (state, getters) => {
      return !!getters.getUserTeamName
    }
  },
  mutations: {
    setAuth(state, auth) {
      state.auth = auth
    },
    setCOVIDRecords(state, records) {
      state.covidRecords = records
      state.syncedRecords = state.syncedRecords.filter(isRIRecord)
    },
    setRIRecords(state, records) {
      state.riRecords = records
      state.syncedRecords = state.syncedRecords.filter(isCovidRecord)
    },
    setPageSize(state, value) {
      state.pageSize = value
    },
    setProgram(state, id) {
      state.programId = id
    },
    setUpsertRecords(state, record) {
      record.timestamp = new Date().getTime()
      state.upsertRecords[record.getVaccinationNumber()] = record
    },
    removeUpsertRecords(state, { vaccinationNumbers, synced }) {
      const upsertRecords = { ...state.upsertRecords }
      const syncedRecords = [...state.syncedRecords]

      for (const vaccinationNumber of vaccinationNumbers) {
        // mark record with vaccinationNumber as not being updated until refresh
        const record = upsertRecords[vaccinationNumber]
        if (record && synced) {
          syncedRecords.push(record)
        }

        delete upsertRecords[vaccinationNumber]
      }

      if (synced) state.syncedRecords = syncedRecords
      state.upsertRecords = upsertRecords
    },
    resetUpsertRecords(state) {
      state.upsertRecords = {}
    }
  },
  actions: {
  }
})

export default store
