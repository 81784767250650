import groupBy from 'lodash/groupBy'

import { ProgramID, ProgramStagesID, TeamUserGroupID, TrackedEntityTypeID } from '../constants/ids'
import { AttributeMapping, DataElementMapping } from '../constants/mappings'
import { ImmunizationVaccineDisplayNameMap } from '../constants/options'
import { toBool } from '../utils/string'
import {
  _fromDataObject,
  _parseItem,
  _toDataObject,
  DataObject,
  Enrollment as TEEnrollment,
  Event as TEEvent,
  TrackedEntity
} from './dhis.tracked-entity'

const StagesID = ProgramStagesID[ProgramID.RI]

const Periods = [
  'Week0', 'Week6', 'Week10',
  'Week14', 'Month6', 'Month9',
  'Month12', 'Month15', 'Year14'
]

const AntigensByPeriod = {
  Week0: ['BCG', 'BOPV0', 'HEPB0'],
  Week6: ['PENTA1', 'PCV1', 'BOPV1', 'ROTA1', 'IPV1'],
  Week10: ['PENTA2', 'PCV2', 'BOPV2', 'ROTA2'],
  Week14: ['PENTA3', 'PCV3', 'BOPV3', 'ROTA3', 'IPV2'],
  Month6: ['_VitaminA1'],
  Month9: ['Measles1', 'YellowFever', '_Meningitis'],
  Month12: ['_VitaminA2'],
  Month15: ['Measles2'],
  Year14: ['HPV1']
}

export class Profile {
  constructor(args) {
    this.birthDate = _parseItem(args.birthDate, 'datetime')
    this.firstName = args.firstName
    this.surname = args.surname
    this.gender = args.gender
    this.settlement = args.settlement
    this.address = args.address
    this.careGiverFirstName = args.careGiverFirstName
    this.careGiverSurname = args.careGiverSurname
    this.careGiverPhoneNumber = args.careGiverPhoneNumber
    this.vaccinationNumber = args.vaccinationNumber
  }

  toDataObject() {
    const args = _toDataObject(this, AttributeMapping.RI)
    return new DataObject(args, 'attribute', 'value')
  }

  static fromDataObject(attributes) {
    const args = _fromDataObject(attributes, AttributeMapping.RI)
    return new Profile(args)
  }
}

export class BirthData {
  static Stage = StagesID.BirthDetails

  constructor(args) {
    this.birthAttendant = args.birthAttendant
    this.birthFacility = args.birthFacility
    this.birthSettlement = args.birthSettlement
    this.birthType = args.birthType
    this.birthWeight = args.birthWeight
    this.deliveryMode = args.deliveryMode
    this.deliveryPlace = args.deliveryPlace
    this.gestationalAge = args.gestationalAge
    this.parity = args.parity
  }

  toDataObject() {
    const args = _toDataObject(this, DataElementMapping.RI)
    return new DataObject(args, 'dataElement', 'value')
  }

  static fromDataObject(values) {
    const args = _fromDataObject(values, DataElementMapping.RI)
    return new BirthData(args)
  }
}

export class VaccinationEntry {
  constructor(args) {
    this.date = args.date
    this.name = args.name
    this.label = args.label
    this.taken = args.taken
    this.missing = args.missing
    this.saved = args.saved

    // computed property
    this.disabled = !!this.missing || !!this.saved
  }

  /**
   * Converts an VaccinationEntry object into a DataObject that has DHIS ID values as keys
   * and values are taken from the appropriate source object field.
   */
  toDataObject() {
    const mapping = DataElementMapping.RI
    const antigenId = mapping.Field[`antigen${this.name}`].id

    const args = { [antigenId]: this.taken }
    return new DataObject(args, 'dataElement', 'value')
  }

  /**
   * Reshapes a DataObject that has DHIS ID values as keys into an VaccinationEntry object.
   * @param {DataObject} values object to be reshaped
   * @param {string} name antigen name
   * @param {Date} eventDate date antigen was administered
   */
  static fromDataObject(values, name, eventDate, saved) {
    const missing = name.startsWith('_')
    name = !missing ? name : name.slice(1)
    const taken = toBool(values[`antigen${name}`])

    const args = {
      name,
      taken,
      missing,
      date: eventDate,
      label: ImmunizationVaccineDisplayNameMap[name],
      saved: taken ? saved : false
    }
    return new VaccinationEntry(args)
  }
}

/**
 * Represents a record for storing all antigen data within a single Enrollment Immunization Event.
 *
 * Limitation: only a single date can be capture for all stored antigens using the eventDate field
 * of the Event object.
 */
export class VaccinationRecords {
  static Stage = StagesID.Immunization

  constructor(args) {
    // simple data fields
    this.birthRegistrationDate = args.birthRegistrationDate
    this.hasCollectedBirthCertificate = args.hasCollectedBirthCertificate
    this.hasHighTemperatureOrVerySick = args.hasHighTemperatureOrVerySick
    this.hasVaccineAllergy = args.hasVaccineAllergy
    this.vaccinationLocation = args.vaccinationLocation

    // complex data
    this.data = Periods.reduce((dict, period) => ({
      ...dict,
      [period]: AntigensByPeriod[period].map((name, index) => {
        args.data = args.data || {}
        args.data[period] = args.data[period] || []

        const vaccinationList = args.data[period]
        if (vaccinationList.length > index) {
          return new VaccinationEntry(vaccinationList[index])
        }

        return VaccinationEntry.fromDataObject({}, name)
      })
    }), {})
  }

  toDataObject() {
    const { data, ...fields } = this
    let args = _toDataObject(fields, DataElementMapping.RI)
    for (const period of Periods) {
      for (const vaccination of data[period]) {
        args = { ...args, ...vaccination.toDataObject() }
      }
    }

    return new DataObject(args, 'dataElement', 'value')
  }

  static fromDataObject(values) {
    const args = _fromDataObject(values, DataElementMapping.RI)
    const data = Periods.reduce((dict, period) => ({
      ...dict,
      [period]: AntigensByPeriod[period].map(
        (name) => VaccinationEntry.fromDataObject(values, name)
      )
    }), {})

    return new VaccinationRecords({ ...args, data })
  }
}

/**
 * Represents a record for storing a single antigen data together with date administered within a
 * single Enrollment Immunization Event.
 *
 * NOTE: This object stores a single antigen data together with other fields repeated across all
 * immunization events.
 */
export class VaccinationData {
  static Stage = StagesID.Immunization

  constructor(args) {
    this.birthRegistrationDate = args.birthRegistrationDate
    this.hasCollectedBirthCertificate = args.hasCollectedBirthCertificate
    this.hasHighTemperatureOrVerySick = args.hasHighTemperatureOrVerySick
    this.hasVaccineAllergy = args.hasVaccineAllergy
    this.vaccinationLocation = args.vaccinationLocation
    this.antigens = args.antigens || VaccinationData.newAntigens()
  }

  toDataObject() {
    const { antigens, ...fields } = this
    let args = _toDataObject(fields, DataElementMapping.RI)
    for (const antigen of Object.values(antigens)) {
      const entry = new VaccinationEntry(antigen)
      if (!entry.missing) {
        args = { ...args, ...entry.toDataObject() }
      }
    }

    return new DataObject(args, 'dataElement', 'value')
  }

  static fromDataObject(values, eventDate, saved) {
    const args = _fromDataObject(values, DataElementMapping.RI)
    const dict = Object.keys(args).reduce((data, field) => {
      const value = args[field]
      if (!field.startsWith('antigen')) {
        data[field] = value
        return data
      }

      const name = field.replace('antigen', '')
      data.antigens[field] = VaccinationEntry.fromDataObject(args, name, eventDate, saved)
      return data
    }, { antigens: VaccinationData.newAntigens() })

    return new VaccinationData(dict)
  }

  static newAntigens() {
    return Object
      .values(AntigensByPeriod)
      .reduce((dict, values) => ([...dict, ...values]), [])
      .reduce((dict, name) => ({
        ...dict,
        [`antigen${name}`]: VaccinationEntry.fromDataObject({}, name, undefined)
      }), {})
  }
}

export class VaccinationEvent {
  constructor(event, _class = VaccinationData) {
    this._class = _class
    this.programStage = _class.Stage
    this.values = new _class(event.values)

    this.modified = event?.modified || false
    this.event = typeof event?.event === 'string' ? event : event?.event

    const dateValue = event.vaccinationDate || this.event?.eventDate
    this.vaccinationDate = dateValue ? new Date(dateValue) : undefined
  }

  toEntity(userTeam) {
    const { values, ...fields } = this.event || {}
    const eventDate = this.vaccinationDate?.getTime()
    return new TEEvent({
      ...fields,
      programStage: this._class.Stage,
      attributeCategoryOptions: userTeam,
      attributeOptionCombo: TeamUserGroupID[userTeam],
      eventDate: eventDate ? new Date(eventDate) : undefined,
      values: this.values.toDataObject()
    })
  }

  static fromEntity(event, _class = VaccinationData) {
    const { values, ...fields } = event
    const saved = !!event.event

    let eventDate = event.vaccinationDate || event.eventDate
    eventDate = eventDate ? new Date(eventDate) : undefined

    return new VaccinationEvent(
      { ...fields, values: _class.fromDataObject(values, eventDate, saved) },
      _class
    )
  }

  static new(_class) {
    return new VaccinationEvent(
      { values: DataObject.fromEntityJSON([], 'dataElement', 'value') },
      _class
    )
  }
}

export class Enrollment {
  constructor(enrollment) {
    this.enrollment = !['string', 'undefined'].includes(typeof enrollment?.enrollment)
      ? enrollment.enrollment
      : enrollment

    this.birthData = new VaccinationEvent(enrollment.birthData, BirthData)
    this.vaccinations = (enrollment.vaccinations || [])
      .map((vaccination) => new VaccinationEvent(vaccination, VaccinationData))
      .sort((x, y) => {
        if (!!x.vaccinationDate && !!y.vaccinationDate) {
          return x.vaccinationDate > y.vaccinationDate ? 1 : -1 // sort ascending
        } else if (!x.vaccinationDate && !!y.vaccinationDate) {
          return 1 // sort x after b
        } else if (!!x.vaccinationDate && !y.vaccinationDate) {
          return -1 // sort x before b
        }
        return 0
      })
  }

  toEntity() {
    const { birthData: _, vaccinations: __, ...fields } = this.enrollment
    return new TEEnrollment({
      ...fields,
      events: [this.birthData, ...this.vaccinations].map((e) => e.toEntity())
    })
  }

  static fromEntity(enrollment) {
    const { events, ...fields } = enrollment

    // process birth data event
    const event = (events || []).find((e) => e.programStage === StagesID.BirthDetails)
    const birthData = event
      ? VaccinationEvent.fromEntity(event, BirthData)
      : VaccinationEvent.new(BirthData)

    // process immunization events
    const vaccinations = ((events?.length && events) || [VaccinationEvent.new(VaccinationData)])
      .filter((e) => e.programStage === StagesID.Immunization)
      .map((e) => VaccinationEvent.fromEntity(e, VaccinationData))

    return new Enrollment({ ...fields, birthData, vaccinations })
  }
}

export class Record {
  constructor(entity) {
    this.entity = typeof entity?.entity === 'undefined' ? entity : entity?.entity
    this.modified = entity.modified | false
    this.timestamp = entity.timestamp

    this.profile = new Profile(entity.profile)
    this.enrollment = new Enrollment(entity.enrollment)
  }

  getVaccinationNumber() {
    return this.profile?.vaccinationNumber
  }

  isNew() {
    return !this.entity?.trackedEntityInstance
  }

  toEntity(metadata) {
    const { profile: _, enrollment: __, ...fields } = this.entity
    return new TrackedEntity({
      ...fields,
      attributes: this.profile.toDataObject(),
      enrollment: this.enrollment.toEntity()
    })
  }

  static fromEntity(entity) {
    const { attributes, enrollment, ...fields } = entity
    return new Record({
      ...fields,
      profile: Profile.fromDataObject(attributes),
      enrollment: Enrollment.fromEntity(enrollment)
    })
  }

  static fromJSON(data, programId) {
    const entity = TrackedEntity.fromEntityJSON(data, programId, TrackedEntityTypeID.RI)
    return Record.fromEntity(entity)
  }

  static getVaccinationsByPeriod(vaccinations) {
    // collect all antigens within an object
    const antigens = [...vaccinations]
      .reverse()
      .reduce((dict, vaccination) => {
        // pick administered antigens only for existing events
        if (vaccination.event) {
          const administered = Object.values(vaccination.values.antigens)
            .filter((antigen) => antigen.taken)
            .reduce((dict, antigen) => ({ ...dict, [`antigen${antigen.name}`]: antigen }), {})

          return { ...dict, ...administered }
        }

        return { ...dict, ...vaccination.values.antigens }
      }, {})

    // split antigens objects by period
    return Object.entries(AntigensByPeriod)
      .reduce((dict, [period, names]) => {
        const matchedAntigens = names
          .map((name) => antigens[`antigen${name}`])
          .filter((antigen) => !!antigen)

        return {
          ...dict,
          [period]: matchedAntigens
        }
      }, {})
  }

  static new(orgUnit) {
    return Record.fromEntity(TrackedEntity.new(orgUnit, TrackedEntityTypeID.RI))
  }

  /**
   * Reorganize vaccination events to group newly administered antigens by date and save
   * each group to DHIS2 as a separate Enrollment Event.
   *
   * @param {Record} record to be modified
   */
  static forSave(record) {
    const category = groupBy([...record.enrollment.vaccinations], (i) => !i.event ? 'new' : 'old')
    const antigensByDate = groupBy(
      Object.values(category.new[0].values.antigens),
      (antigen) => {
        if (!antigen.taken) return 'skip'
        return new Date(antigen.date).toISOString().slice(0, 10)
      }
    )

    const newVaccinations = Object.keys(antigensByDate)
      .filter((dt) => dt !== 'skip')
      .map((dt) => {
        const vaccination = VaccinationEvent.new(VaccinationData)
        vaccination.values.antigens = antigensByDate[dt]
          .reduce((dict, antigen) => ({ ...dict, [`antigen${antigen.name}`]: antigen }), {})

        vaccination.vaccinationDate = new Date(dt)
        vaccination.modified = true
        return vaccination
      })

    // if an old event exists, it's safe to assume pre-immunization fields already exists there
    // otherwise, we need to transfer the values onto the first newly created vaccination event
    if (!category.old?.length) {
      const { antigens, ...fields } = category.new[0].values
      newVaccinations[0].values = {
        ...newVaccinations[0].values,
        ...fields
      }
    }

    record.enrollment.vaccinations = [...(category.old || []), ...newVaccinations]
    return new Record(record)
  }

  /**
   * Returns a new RI record with a new blank vaccination event added to `vaccinations` in order to
   * handle new antigen selections appropriately by adding storing such selections on the new blank
   * vaccination event.
   *
   * @param {Record} record
   */
  static forUpdate(record) {
    const newRecord = new Record(record)
    newRecord.enrollment.vaccinations.push(VaccinationEvent.new(VaccinationData))
    return newRecord
  }
}
