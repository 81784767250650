<template>
  <footer class="footer is-fixed-bottom has-text-centered">
    <strong>{{ appFullName }}</strong><br>
    <em>{{ appVersion }}</em><br>
    <em
      v-show="appRevision"
      class="has-text-grey-lighter is-size-7"
    >
      build: {{ appRevision }}
    </em>

    <b-notification
      v-if="isOffline"
      aria-close-label="Close notification"
      role="alert"
      type="is-danger"
    >
      You are Offline!
    </b-notification>
  </footer>
</template>

<script>
import { APP_FULL_NAME, APP_REVISION, APP_VERSION } from '../constants/env'

export default {
  name: 'EFooter',
  computed: {
    appFullName() { return APP_FULL_NAME },
    appRevision() { return APP_REVISION },
    appVersion() { return APP_VERSION }
  }
}
</script>

<style scoped>
  .main {
    display: flex;
    min-height: 100vh;
    flex-direction: column;
  }

  .section {
    flex: 1;
    min-height: 100vh;
  }
</style>
