import { OrganisationUnit } from './dhis.org'

export class UserGroup {
  static Fields = ['id', 'name']
  static RE_TEAM = /^(Fixed Team|Team.\d|NPSIA)$/

  constructor(args) {
    this.id = args.id
    this.name = args.name
  }

  static fromJSON(data) {
    const args = UserGroup.Fields.reduce(
      (userGroup, field) => ({
        ...userGroup,
        [field]: data[field]
      }),
      {}
    )

    return new UserGroup(args)
  }
}

export class User {
  static Fields = [
    'id',
    'displayName',
    'firstName',
    'surname',
    'userCredentials[username]',
    'userGroups[id,name]',
    'organisationUnits[id,displayName]'
  ]

  constructor(args) {
    this.id = args.id
    this.username = args.username
    this.userGroup = args.userGroup
    this.displayName = args.displayName
    this.firstName = args.firstName
    this.surname = args.surname
    this.organisation = args.organisation
  }

  static fromJSON(data) {
    const organisation = (data.organisationUnits || []).map(OrganisationUnit.fromJSON)[0]
    const userGroup = (data.userGroups || [])
      .map(UserGroup.fromJSON)
      .find(ug => ug.name.match(UserGroup.RE_TEAM))

    const username = data.userCredentials?.username
    const args = User.Fields.slice(0, -2).reduce(
      (user, field) => ({
        ...user,
        [field]: data[field]
      }),
      { username, userGroup, organisation }
    )

    return new User(args)
  }
}
