import { OrganisationUnit } from './dhis.org'
import { Record as CovidRecord } from './dhis.record-covid'
import { Record as RIRecord } from './dhis.record-ri'
import { TrackedEntity } from './dhis.tracked-entity'
import { User } from './dhis.user'

const isRIRecord = (record) => ('birthData' in (record.enrollment || {}))

const isCovidRecord = (record) => !isRIRecord(record)

export {
  CovidRecord,
  isCovidRecord,
  isRIRecord,
  OrganisationUnit,
  RIRecord,
  TrackedEntity,
  User
}
