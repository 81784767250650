<template>
  <b-tab-item
    :disabled="disabled"
    :label="label"
  >
    <e-select
      v-show="indicateLocation"
      label="Was Vaccine Received Outside Nigeria?"
      placeholder="Yes or No?"
      :options="yesNoChoices()"
      :value="computedValue.values.outsideNigeria"
      @input="updateValues('outsideNigeria', $event)"
    />
    <e-select
      label="Vaccine Type"
      placeholder="Select Vaccine"
      :options="choices.vaccines"
      :value="computedValue.values.vaccineType"
      @input="updateValues('vaccineType', $event)"
    />

    <e-date-picker
      label="Vaccination Date"
      :min-date="doseMinDate()"
      :max-date="maxVaccinationDate"
      :value="computedValue.vaccinationDate"
      @input="updateValue('vaccinationDate', $event)"
    />

    <b-field
      label-position="on-border"
      label="Vaccine Batch Number"
      class="mb-5"
    >
      <b-autocomplete
        expanded
        open-on-focus
        rounded
        :data="choices.batches"
        :readonly="isBatchReadonly"
        :value="computedValue.values.vaccineBatchNumber"
        @input="updateValues('vaccineBatchNumber', $event)"
      />
    </b-field>
  </b-tab-item>
</template>

<script>
import { mapState } from 'vuex'

import { EDatePicker, ESelect } from '../components/generic'
import { VaccinationData, VaccinationEvent } from '../models/dhis.record-covid'
import { yesNoChoices } from '../utils/choices'

export default {
  name: 'ECovidVaccinationForm',
  components: {
    EDatePicker,
    ESelect
  },
  props: {
    disabled: { type: Boolean, default: false },
    isBatchReadonly: { type: Boolean, default: false },
    indicateLocation: { type: Boolean, default: false },
    label: { type: String, default: '' },
    maxVaccinationDate: { type: Date, default: undefined },
    value: { type: Object, default: VaccinationEvent.new(VaccinationData) },
    choices: {
      type: Object,
      default() {
        return {
          batches: [],
          vaccines: []
        }
      }
    }
  },
  data() {
    return {
      newValue: this.value
    }
  },
  computed: {
    ...mapState(['vaccinationStartDate']),
    computedValue: {
      get() {
        return this.newValue ? this.newValue : VaccinationEvent.new(VaccinationData)
      },
      set(value) {
        this.newValue = value
        this.$emit('input', value)
      }
    }
  },
  watch: {
    value(value) {
      this.newValue = value
    },
    'computedValue.values': {
      handler(val) {
        // auto-set the "vaccinated" field when vaccineType and vaccinationDate is set
        this.newValue.values.vaccinated =
          (val.vaccineType && this.newValue.vaccinationDate) ? true : undefined
      }
    }
  },
  methods: {
    yesNoChoices,

    doseMinDate() {
      return new Date(this.vaccinationStartDate)
    },
    updateValue(key, value) {
      this.computedValue = { ...this.computedValue, modified: true, [key]: value }
    },
    updateValues(key, value) {
      this.updateValue('values', { ...this.computedValue.values, [key]: value })
    }
  }
}
</script>

<style>
  .b-tabs .tab-content {
    padding: 1rem 0 0 0;
  }

  .content ul {
    margin-left: 0;
  }
</style>
