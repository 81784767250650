<template>
  <b-field
    label-position="on-border"
    :class="customClass"
    :label="label"
    :type="!!error ? 'is-danger' : ''"
    :message="error"
  >
    <b-input
      ref="input"
      v-bind="$attrs"
      expanded
      rounded
      :disabled="disabled"
      :placeholder="placeholder || label"
      :type="type"
      :value="computedValue"
      @change="updateValue"
      @input="updateValue"
      @icon-right-click="$emit('icon-right-click', $event)"
    />
  </b-field>
</template>

<script>
import FormElementMixin from '../../mixins/component-mixin'

export default {
  name: 'EInput',
  mixins: [FormElementMixin],
  props: {
    disabled: { type: Boolean, default: false },
    size: { type: String, default: '' },
    type: { type: String, default: 'text' },
    value: { type: [Number, String], default: '' }
  },
  data() {
    return {
      newValue: this.value
    }
  },
  computed: {
    computedValue: {
      get() {
        return this.newValue
      },
      set(value) {
        this.newValue = value
        this.$emit('input', value)
      }
    }
  },
  watch: {
    /**
     * When v-model is changed:
     *   1. Set internal value.
     */
    value(value) {
      this.newValue = value
    }
  },
  methods: {
    updateValue(value) {
      this.computedValue = value
    }
  }
}
</script>
