<template>
  <b-notification
    :type="type"
    aria-close-label="Close notification"
    role="alert"
    class="column"
  >
    <slot />
  </b-notification>
</template>

<script>
export default {
  name: 'ENotification',
  props: {
    type: {
      type: String,
      default: ''
    }
  }
}
</script>
