import { Buffer } from 'buffer'

export function getBasicAuthHeaders(token) {
  return {
    Authorization: `Basic ${token}`
  }
}

export function getBasicAuthToken(username, password) {
  return Buffer
    .from(`${username}:${password}`, 'utf-8')
    .toString('base64')
}

export function isCredentialEmptyOrNull(username, password) {
  return (
    (!username || username.length <= 1) ||
    (!password || password.length <= 1)
  )
}
