<template>
  <hr
    class="m-0 p-0"
    :style="{borderColor: color}"
  >
</template>

<script>
import { APP_STRIP_COLOR } from '../constants/env'

export default {
  name: 'EStrip',
  data() {
    return {
      color: APP_STRIP_COLOR || 'orange'
    }
  }
}
</script>

<style scoped>
  hr {
    border-style: solid;
    border-width: 4px;
  }
</style>
