import axios from 'axios'

import { updateEventDates, updateTrackedEntityDates } from './date'

const httpClient = axios.create({
  transformRequest: [(data, headers) => {
    if (data && (typeof data === 'object')) {
      if ('trackedEntityInstances' in data) {
        data
          .trackedEntityInstances
          .forEach(trackEntity => updateTrackedEntityDates(trackEntity))
      } else if ('events' in data) {
        updateEventDates(data.events)
      } else if ('profile' in data) {
        updateTrackedEntityDates(data)
      }
    }
    /* Update content type to application/json default is application/www-url-encoded */
    Object
      .keys(headers)
      .filter(key => ['patch', 'put', 'post'].includes(key))
      .forEach(method => {
        headers[method]['Content-Type'] = 'application/json'
      })

    return JSON.stringify(data)
  }]
})

httpClient.interceptors.response.use(function (response) {
  if ('trackedEntityInstances' in response.data) {
    response.data.trackedEntityInstances.sort((a, b) => a.created < b.created)
  }
  return response
})

export default httpClient
