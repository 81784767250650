var render = function render(){var _vm=this,_c=_vm._self._c;return (!_vm.isEditMode)?_c('div',[_c('div',{staticClass:"buttons"},[_c('b-button',{directives:[{name:"show",rawName:"v-show",value:(!_vm.searchOnline),expression:"!searchOnline"}],attrs:{"label":"Fetch Recent Records","type":"is-success","disabled":_vm.isLoading},on:{"click":_vm.fetchData}})],1),(_vm.searchOnline)?_c('e-search-online',{attrs:{"entity-type":_vm.entityType,"search-term":_vm.searchTerm,"search-attributes":_vm.searchAttributes},on:{"complete":_vm.updateTable,"cancel":_vm.cancelOnlineSearch}}):_vm._e(),_c('vue-good-table',{attrs:{"columns":_vm.columns,"rows":_vm.records,"pagination-options":{
      allLabel: 'more',
      enabled: true,
      position: 'top',
      rowsPerPageLabel: 'Listing',
      infoFn: (params) => `Recent records...`,
    },"row-style-class":_vm.rowStyleClassFn,"search-options":{ enabled: true, placeholder: 'Search for Records...' }},on:{"on-row-click":_vm.onRowSelected,"on-search":_vm.onSearch}},[_c('div',{attrs:{"slot":"emptystate"},slot:"emptystate"},[_c('span',[_vm._v("Click on "),_c('strong',[_vm._v(" Fetch Recent Records ")])])])])],1):_c('div',[_c('e-ri-form',{attrs:{"data":_vm.selectedRecord},on:{"go-back":function($event){_vm.selectedRecord = undefined},"submit":_vm.saveUpdatedRecord}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }