<template>
  <div class="page not-found">
    <div class="columns">
      <div class="column">
        <h1 class="title">
          Page not found
        </h1>

        <p>Oh oh... you're in the wrong page!</p>
        <router-link
          class="button"
          to="/"
        >
          Click to go back!
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PageNotFound'
}
</script>

<style lang="sass" scoped>
</style>
