<template>
  <div class="columns is-desktop is-centered">
    <div class="column is-half-desktop mt-4">
      <!-- profile form-->
      <div class="profile">
        <b-field
          grouped
          label-position="on-border"
        >
          <b-button
            label="Go Back"
            type="is-dark"
            @click="$emit('go-back', $event)"
          />
        </b-field>

        <pre> {{ editMode ? 'Update': 'Create' }} Routine Immunization (RI) record </pre>

        <div class="p-2 mb-4 has-text-left has-background-info-light">
          <strong>Child Details</strong>
        </div>

        <e-input
          required
          label="* Vaccination Number"
          placeholder="Vaccination Number"
          :disabled="true"
          :error="errors.vaccinationNumber"
          :value="computedValue.profile.vaccinationNumber"
          @input="updateProfile('vaccinationNumber', $event)"
        />

        <e-input
          label="First Name"
          placeholder="First Name"
          :error="errors.firstName"
          :value="computedValue.profile.firstName"
          @input="updateProfile('firstName', $event)"
        />

        <e-input
          required
          label="* Surname"
          placeholder="Surname"
          :error="errors.surname"
          :value="computedValue.profile.surname"
          @input="updateProfile('surname', $event)"
        />

        <e-date-picker
          required
          label="* Date of Birth"
          placeholder="Birth Date"
          :error="errors.birthDate"
          :value="computedValue.profile.birthDate"
          :max-date="maxBirthDate"
          :min-date="minBirthDate"
          @input="updateProfile('birthDate', $event)"
        />

        <e-select
          required
          label="* Gender"
          placeholder="Select Gender"
          :error="errors.gender"
          :options="genderChoices()"
          :value="computedValue.profile.gender"
          @input="updateProfile('gender', $event)"
        />

        <e-select
          label="Place of Delivery"
          placeholder="Select Place"
          :error="errors.deliveryPlace"
          :options="deliveryPlaceChoices()"
          :value="computedValue.enrollment.birthData.values.deliveryPlace"
          @input="updateBirthData('deliveryPlace', $event)"
        />

        <e-input
          required
          label="* Village/Settlement"
          placeholder="Village/Settlement"
          :error="errors.settlement"
          :value="computedValue.profile.settlement"
          @input="updateProfile('settlement', $event)"
        />

        <e-input
          required
          label="* Address (Traceable)"
          placeholder="Address (Traceable)"
          :error="errors.address"
          :value="computedValue.profile.address"
          @input="updateProfile('address', $event)"
        />

        <div class="p-2 mb-4 has-text-left has-background-info-light">
          <strong>Care Giver Details</strong>
        </div>

        <e-input
          required
          label="* First Name"
          placeholder="First Name"
          :error="errors.careGiverFirstName"
          :value="computedValue.profile.careGiverFirstName"
          @input="updateProfile('careGiverFirstName', $event)"
        />

        <e-input
          required
          label="* Surname"
          placeholder="Surname"
          :error="errors.careGiverSurname"
          :value="computedValue.profile.careGiverSurname"
          @input="updateProfile('careGiverSurname', $event)"
        />

        <e-input
          required
          label="* Phone Number"
          placeholder="Phone Number"
          minlength="10"
          type="number"
          :error="errors.careGiverPhoneNumber"
          :value="computedValue.profile.careGiverPhoneNumber"
          @input="updateProfile('careGiverPhoneNumber', $event)"
        />
      </div>

      <!-- vaccination forms -->
      <b-tabs
        v-show="showTabs"
        v-model="activeTabIndex"
        class="mb-0"
      >
        <e-vaccination-form
          :age-durations="ageDurations"
          :value="computedValue.enrollment.vaccinations"
          @input="updateVaccinations('vaccinations', $event)"
        />

        <e-vaccination-dates-form
          v-if="hasHistoricalImmunization"
          :age-durations="ageDurations"
          :min-date="computedValue.profile.birthDate"
          :max-date="maxBirthDate"
          :value="computedValue.enrollment.vaccinations"
          @input="updateVaccinations('vaccinations', $event)"
        />
      </b-tabs>

      <b-button
        expanded
        rounded
        size="is-large"
        type="is-success"
        label="Save Record"
        @click="submitForm"
      />
    </div>
  </div>
</template>

<script>
import cloneDeep from 'lodash/cloneDeep'
import isEmpty from 'lodash/isEmpty'
import { computed } from 'vue'

import { EDatePicker, EInput, ESelect } from '../components/generic'
import { DeliveryPlace, Gender } from '../constants/options'
import { RIRecord } from '../models'
import {
  birthAttendantChoices,
  birthTypeChoices,
  deliveryModeChoices,
  deliveryPlaceChoices,
  genderChoices,
  yesNoChoices
} from '../utils/choices'
import { ageDurations, isAgeAbove } from '../utils/date'
import { generateVaccinationNumber } from '../utils/string'
import EVaccinationDatesForm from './ri-vaccination-dates-form'
import EVaccinationForm from './ri-vaccination-form'

const MaxAgeAllowed = 14
const MaxAgeAllowedError = `Data capture not allowed for children older than ${MaxAgeAllowed} years`

const RequiredFields = [
  'address',
  'birthDate',
  'careGiverFirstName',
  'careGiverSurname',
  'careGiverPhoneNumber',
  'gender',
  'settlement',
  'surname',
  'vaccinationNumber'
]

export default {
  components: {
    EDatePicker,
    EVaccinationForm,
    EVaccinationDatesForm,
    EInput,
    ESelect
  },
  provide () {
    return {
      disabledAntigens: computed(() => this.disabledAntigens)
    }
  },
  props: {
    data: { type: Object, default: null },
    value: { type: Object, default() { return RIRecord.new() } }
  },
  emits: ['go-back', 'input'],
  data() {
    return {
      DeliveryPlace: Object.freeze(DeliveryPlace),

      errors: {},
      newValue: this.value,

      activeTabIndex: 0,
      disabledAntigens: [],
      editMode: false,
      hasHistoricalImmunization: true
    }
  },
  computed: {
    computedValue: {
      get() {
        return this.newValue ? this.newValue : RIRecord.new()
      },
      set(value) {
        this.newValue = value
        this.$emit('input', value)
      }
    },
    ageDurations() {
      const birthDate = this.computedValue.profile.birthDate
      if (birthDate) {
        return ageDurations(birthDate)
      }
      return undefined
    },
    maxBirthDate() {
      return new Date()
    },
    minBirthDate() {
      const dt = new Date()
      dt.setFullYear(dt.getFullYear() - MaxAgeAllowed)
      dt.setMonth(dt.getMonth() - 3)
      return dt
    },
    showTabs() {
      return !!this.computedValue.profile.birthDate
    }
  },
  watch: {
    value(value) {
      this.newValue = value
    },
    hasHistoricalImmunization(value) {
      if (value === false && this.activeTabIndex === 1) {
        this.activeTabIndex = 0
      }
    },
    'computedValue.profile.birthDate': function(newVal) {
      if (isAgeAbove(newVal, MaxAgeAllowed)) {
        this.$buefy.toast.open(MaxAgeAllowedError)
      }
      // Disable BOPV0 and HEPB0 for child of at least two weeks (14+ days) old
      this.disabledAntigens = this.ageDurations?.days > 14
        ? ['BOPV0', 'HEPB0']
        : []
    },
    'computedValue.profile.gender': function() {
      // HPV1 is only available for girls
      if (this.computedValue.profile.gender !== Gender.Female) {
        this.disabledAntigens.push('HPV1')
      }
    }
  },
  mounted() {
    if (!isEmpty(this.data)) {
      this.newValue = cloneDeep(new RIRecord(this.data))
      this.editMode = true
    } else {
      this.newValue.profile.vaccinationNumber = generateVaccinationNumber()
    }
  },
  methods: {
    birthAttendantChoices,
    birthTypeChoices,
    deliveryModeChoices,
    deliveryPlaceChoices,
    genderChoices,
    yesNoChoices,

    isFormValid() {
      const errors = {}
      for (const field of RequiredFields) {
        const value = this.computedValue.profile[field]
        if (!value) {
          errors[field] = 'Please fill in this field.'
        }
      }

      if (isAgeAbove(this.computedValue.profile.birthDate, MaxAgeAllowed)) {
        errors._message = MaxAgeAllowedError
      }

      if (!isEmpty(errors)) {
        this.errors = errors
        window.scrollTo(0, 0)
        return false
      }

      return true
    },
    submitForm() {
      if (!this.isFormValid()) {
        this.$buefy.toast.open(this.errors._message || 'Invalid inputs provided')
        return
      }

      this.$emit('submit', new RIRecord(this.computedValue))
    },
    updateValue(key, value) {
      this.computedValue = { ...this.computedValue, [key]: value }
    },
    updateProfile(key, value) {
      delete this.errors[key]
      this.computedValue.modified = true
      this.updateValue('profile', { ...this.computedValue.profile, [key]: value })
    },
    updateBirthData(key, value) {
      delete this.errors[key]

      // update value on birthData
      const birthData = { ...this.computedValue.enrollment.birthData }
      birthData.values[key] = value
      birthData.modified = true
      birthData.vaccinationDate = new Date()

      // update birthData on enrollment
      const enrollment = { ...this.computedValue.enrollment, birthData }
      this.updateValue('enrollment', enrollment)
    },
    updateVaccinations(key, value) {
      const enrollment = { ...this.computedValue.enrollment, [key]: [...value] }
      this.updateValue('enrollment', enrollment)
    }
  }
}
</script>
