import { ProgramID, ProgramStagesID, TeamUserGroupID, TrackedEntityTypeID } from '../constants/ids'
import { AttributeMapping, DataElementMapping } from '../constants/mappings'
import {
  _fromDataObject,
  _parseItem,
  _toDataObject,
  DataObject,
  Enrollment as TEEnrollment,
  Event as TEEvent,
  TrackedEntity
} from './dhis.tracked-entity'

const StagesID = ProgramStagesID[ProgramID.COVID]

export const DOSES = ['DOSE1', 'DOSE2', 'BOOSTER', 'BOOSTER2']

export class Profile {
  constructor(args) {
    this.age = Number(args.birthDate || args.age) || 0
    this.birthDate = _parseItem(args.birthDate, 'datetime')
    this.familyName = args.familyName
    this.otherName = args.otherName
    this.residentialAddress = args.residentialAddress
    this.idType = args.idType
    this.idNumber = args.idNumber
    this.isAgeEstimated = args.isAgeEstimated
    this.email = args.email
    this.phoneNumber = args.phoneNumber
    this.occupation = args.occupation
    this.hasAllergies = args.hasAllergies
    this.gender = args.gender
    this.pregnancyStatus = args.pregnancyStatus
    this.vaccinationNumber = args.vaccinationNumber
  }

  toDataObject() {
    const args = _toDataObject(this, AttributeMapping.COVID)
    return new DataObject(args, 'attribute', 'value')
  }

  static fromDataObject(attributes) {
    const args = _fromDataObject(attributes, AttributeMapping.COVID)
    return new Profile(args)
  }
}

export class VerificationData {
  static Stage = StagesID.Verification

  constructor(args) {
    this.preSelectedBySystem = args.preSelectedBySystem
    this.verified = args.verified
  }

  toDataObject() {
    const args = _toDataObject(this, DataElementMapping.COVID)
    return new DataObject(args, 'dataElement', 'value')
  }

  static fromDataObject(values) {
    const args = _fromDataObject(values, DataElementMapping.COVID)
    return new VerificationData(args)
  }
}

export class VaccinationData {
  static Stage = StagesID.Vaccination

  constructor(args) {
    this.doseNumber = args.doseNumber
    this.outsideNigeria = args.outsideNigeria
    this.qrCode = args.qrCode
    this.vaccinated = args.vaccinated
    this.vaccineType = args.vaccineType
    this.vaccineBatchNumber = args.vaccineBatchNumber
  }

  toDataObject() {
    const args = _toDataObject(this, DataElementMapping.COVID)
    return new DataObject(args, 'dataElement', 'value')
  }

  static fromDataObject(values) {
    const args = _fromDataObject(values, DataElementMapping.COVID)
    return new VaccinationData(args)
  }
}

export class VaccinationEvent {
  constructor(event, _class = VaccinationData) {
    this._class = _class
    this.values = new _class(event.values)

    this.modified = event?.modified || false
    this.event = typeof event?.event === 'string' ? event : event?.event

    const dateValue = event.vaccinationDate || this.event?.eventDate
    this.vaccinationDate = dateValue ? new Date(dateValue) : undefined
  }

  toEntity(userTeam) {
    const { values, ...fields } = this.event || {}
    const eventDate = this.vaccinationDate?.getTime()

    return new TEEvent({
      ...fields,
      programStage: this._class.Stage,
      attributeCategoryOptions: userTeam,
      attributeOptionCombo: TeamUserGroupID[userTeam],
      eventDate: eventDate ? new Date(eventDate) : undefined,
      values: this.values.toDataObject()
    })
  }

  static fromEntity(event, _class = VaccinationData) {
    const { values, ...fields } = event
    return new VaccinationEvent(
      { ...fields, values: _class.fromDataObject(values) },
      _class
    )
  }

  static new(_class) {
    return new VaccinationEvent(
      { values: DataObject.fromEntityJSON([], 'dataElement', 'value') },
      _class
    )
  }
}

export class Enrollment {
  constructor(enrollment) {
    this.enrollment = ['string', 'undefined'].includes(typeof enrollment?.enrollment)
      ? enrollment
      : enrollment.enrollment

    this.verification = new VaccinationEvent(enrollment.verification, VerificationData)
    this.vaccinations = enrollment.vaccinations.map((entry) => {
      return { ...entry, data: new VaccinationEvent(entry.data, VaccinationData) }
    })
  }

  toEntity() {
    const { verification: _, vaccinations: __, ...fields } = this.enrollment
    const vaccinations = this.vaccinations.map((v) => v.data)
    return new TEEnrollment({
      ...fields,
      events: [this.verification, ...vaccinations].map((v) => v.toEntity())
    })
  }

  static fromEntity(enrollment) {
    const { events, ...fields } = enrollment

    // process verification event
    const event = events.find((e) => e.programStage === StagesID.Verification)
    const verification = event
      ? VaccinationEvent.fromEntity(event, VerificationData)
      : VaccinationEvent.new(VerificationData)

    // process vaccination events
    const matchedEvents = events
      .filter((e) => e.programStage === StagesID.Vaccination)
      .map((e) => VaccinationEvent.fromEntity(e, VaccinationData))

    const vaccinations = DOSES.map((dose) => {
      const event = matchedEvents.find((e) => e.values.doseNumber === dose)
      const data = event || VaccinationEvent.new(VaccinationData)
      data.values.doseNumber = dose

      return {
        data,
        key: dose,
        indicateLocation: dose === 'DOSE1'
      }
    })

    return new Enrollment({ ...fields, verification, vaccinations })
  }
}

export class Record {
  constructor(entity) {
    this.entity = typeof entity?.entity === 'undefined' ? entity : entity?.entity
    this.modified = entity.modified || false
    this.timestamp = entity.timestamp

    this.profile = new Profile(entity.profile)
    this.enrollment = new Enrollment(entity.enrollment)

    this.verification = this.enrollment.verification
    this.vaccinations = this.enrollment.vaccinations
  }

  isNew() {
    return !this.entity?.trackedEntityInstance
  }

  getVaccinationNumber() {
    return this.profile?.vaccinationNumber
  }

  toEntity() {
    const { profile: _, enrollment: __, ...fields } = this.entity
    return new TrackedEntity({
      ...fields,
      attributes: this.profile.toDataObject(),
      enrollment: this.enrollment.toEntity()
    })
  }

  static fromEntity(entity) {
    const { attributes, enrollment, ...fields } = entity
    return new Record({
      ...fields,
      profile: Profile.fromDataObject(attributes),
      enrollment: Enrollment.fromEntity(enrollment)
    })
  }

  static fromJSON(data, programId) {
    const entity = TrackedEntity.fromEntityJSON(data, programId, TrackedEntityTypeID.COVID)
    return Record.fromEntity(entity)
  }

  static new(orgUnit) {
    return Record.fromEntity(TrackedEntity.new(orgUnit, TrackedEntityTypeID.COVID))
  }
}
