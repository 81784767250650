<template>
  <b-tab-item
    label="Historical Dates"
  >
    <e-collapse-card
      v-for="(period) in riVaccinationPeriods"
      :key="period"
      :title="cardTitle(period)"
    >
      <e-datetime-list
        :max-date="maxDate"
        :min-date="minDate"
        :value="getVaccinationsByPeriod[period]"
        @input="updateAntigens(period, $event)"
      />
    </e-collapse-card>
  </b-tab-item>
</template>

<script>

import ECollapseCard from '../components/collapse-card'
import EDatetimeList from '../components/datetime-list'
import { RIVaccinationPeriodDisplayNameMap } from '../constants/options'
import { Record as RIRecord, VaccinationEntry } from '../models/dhis.record-ri'
import {
  hasVaccineAllergyChoices,
  riVaccinationLocationChoices,
  riVaccinationPeriodsByDuration,
  yesNoChoices
} from '../utils/choices'

export default {
  name: 'ERIVaccinationDatesForm',
  components: {
    ECollapseCard,
    EDatetimeList
  },
  inject: {
    disabledAntigens: {
      default: []
    }
  },
  props: {
    ageDurations: { type: Object, default: undefined },
    maxDate: { type: [Date, String], default: undefined },
    minDate: { type: [Date, String], default: undefined },
    value: { type: Array, default() { return [] } }
  },
  data() {
    return {
      errors: {},
      newValue: this.value
    }
  },
  computed: {
    computedValue: {
      get() {
        return this.newValue ? this.newValue : []
      },
      set(value) {
        this.newValue = value
        this.$emit('input', value)
      }
    },
    getVaccinationsByPeriod() {
      const vaccinationsByPeriod = RIRecord.getVaccinationsByPeriod(this.computedValue)

      // Block `disabledAntigens` from been selected
      Object
        .keys(vaccinationsByPeriod)
        .forEach(period => {
          vaccinationsByPeriod[period] = vaccinationsByPeriod[period]
            .map(antigen => this.disabledAntigens.includes(antigen.name)
              ? new VaccinationEntry({ ...antigen, missing: true })
              : antigen
            )
        })

      return vaccinationsByPeriod
    },
    riVaccinationPeriods() {
      return riVaccinationPeriodsByDuration(this.ageDurations || { weeks: 0, months: 0, years: 0 })
    }
  },
  watch: {
    value(value) {
      this.newValue = value
    }
  },
  methods: {
    hasVaccineAllergyChoices,
    riVaccinationLocationChoices,
    yesNoChoices,

    cardTitle(period) {
      return RIVaccinationPeriodDisplayNameMap[period]
    },
    updateAntigens(period, updatedAntigens) {
      // pick antigens from the last item in the array
      const antigens = { ...this.computedValue.slice(-1)[0].values.antigens }
      const data = updatedAntigens
        .filter((antigen) => antigen._updated)
        .reduce((dict, antigen) => ({
          ...dict,
          [`antigen${antigen.name}`]: new VaccinationEntry(antigen)
        }), {})

      this.updateValues('antigens', { ...antigens, ...data })
    },
    updateValues(key, value) {
      // update values for the last item in the array
      const index = this.computedValue.length - 1
      const values = { ...this.computedValue[index].values }
      values[key] = value

      const computedValue = [...this.computedValue]
      computedValue[index] = {
        ...computedValue[index],
        vaccinationDate: new Date(),
        modified: true,
        values
      }

      this.computedValue = computedValue
    }
  }
}
</script>
