<template>
  <div class="pt-2">
    <e-date-picker
      v-for="(item, index) in computedValue"
      :key="item.key"
      :label="item.label"
      :max-date="item.disabled ? new Date(item.date) : maxDate"
      :min-date="item.disabled ? new Date(item.date) : minDate"
      :placeholder="`Date for ${item.label}`"
      :value="item.date"
      @input="updateValue(item.key, index, $event)"
    />
  </div>
</template>

<script>
import { EDatePicker } from './generic'

export default {
  name: 'EDatetimeList',
  components: {
    EDatePicker
  },
  props: {
    maxDate: { type: [Date, String], default: undefined },
    minDate: { type: [Date, String], default: undefined },
    value: { type: Array, default() { return [] } }
  },
  data() {
    return {
      newValue: this.value
    }
  },
  computed: {
    computedValue: {
      get() {
        return this.newValue ? this.newValue : []
      },
      set(value) {
        this.newValue = value
        this.$emit('input', value)
      }
    }
  },
  watch: {
    value(value) {
      this.newValue = value
    }
  },
  methods: {
    updateValue(key, index, value) {
      const data = [...(this.computedValue || [])]
      data[index] = {
        ...(this.computedValue[index] || {}),
        date: value,
        taken: true,
        _updated: true // metadata
      }

      this.computedValue = data
    }
  }
}
</script>
