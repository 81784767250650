import capitalize from 'lodash/capitalize'

import { ProgramID, TeamCategoryOptions } from '../constants/ids'
import {
  BirthAttendant,
  BirthAttendantDisplayNameMap,
  BirthType,
  DeliveryMode,
  DeliveryModeDisplayNameMap,
  DeliveryPlace,
  DeliveryPlaceDisplayNameMap,
  Gender,
  IDTypeDisplayNameMap,
  IDTypeOrderList,
  OccupationCategory,
  RIVaccinationLocation,
  RIVaccinationLocationDisplayNameMap,
  RIVaccinationPeriod,
  VaccineAllergy
} from '../constants/options'
import States from '../constants/states'
import { VaccineDisplayNameMap, VaccineIDSet } from '../constants/vaccine'

export function birthAttendantChoices() {
  return [
    BirthAttendant.ChewCho,
    BirthAttendant.MidWife,
    BirthAttendant.Nurse,
    BirthAttendant.NurseMidWife,
    BirthAttendant.Physician,
    BirthAttendant.NotStated,
    BirthAttendant.Other
  ].map((id) => ([id, BirthAttendantDisplayNameMap[id]]))
}

export function birthTypeChoices() {
  return [
    BirthType.Singleton,
    BirthType.Twin,
    BirthType.Triplet,
    BirthType.Other,
    BirthType.Unknown
  ].map((id) => [id, capitalize(id)])
}

export function deliveryModeChoices() {
  return [
    DeliveryMode.Normal,
    DeliveryMode.Forceps,
    DeliveryMode.Vacuum,
    DeliveryMode.CSection,
    DeliveryMode.Unknown
  ].map((id) => ([id, DeliveryModeDisplayNameMap[id]]))
}

export function deliveryPlaceChoices() {
  return [
    DeliveryPlace.Home,
    DeliveryPlace.Hospital,
    DeliveryPlace.HealthFacility,
    DeliveryPlace.Unknown
  ].map(
    (id) => ([id, DeliveryPlaceDisplayNameMap[id]])
  )
}

export function genderChoices() {
  return [Gender.Female, Gender.Male].map((id) => ([id, capitalize(id)]))
}

export function hasImmunodeficiencyOrHIVChoices() {
  return ['NO', 'YES', 'UNKNOWN'].map((id) => ([id, capitalize(id)]))
}

export function hasVaccineAllergyChoices() {
  return [
    VaccineAllergy.No,
    VaccineAllergy.Measles,
    VaccineAllergy.RotaVirus,
    VaccineAllergy.Other
  ].map((id) => ([id, capitalize(id)]))
}

export function identificationChoices() {
  return IDTypeOrderList.map((id) => ([id, IDTypeDisplayNameMap[id]]))
}

export function riVaccinationLocationChoices() {
  return [
    RIVaccinationLocation.OtherDistrictFacility,
    RIVaccinationLocation.DistrictFacility,
    RIVaccinationLocation.OutsideDistrict,
    RIVaccinationLocation.MassCampaign,
    RIVaccinationLocation.OutreachCampaign,
    RIVaccinationLocation.OutsideCountry,
    RIVaccinationLocation.Unknown
  ].map((id) => ([id, RIVaccinationLocationDisplayNameMap[id]]))
}

export function riVaccinationPeriods() {
  return [
    RIVaccinationPeriod.Week0,
    RIVaccinationPeriod.Week6,
    RIVaccinationPeriod.Week10,
    RIVaccinationPeriod.Week14,
    RIVaccinationPeriod.Month6,
    RIVaccinationPeriod.Month9,
    RIVaccinationPeriod.Month12,
    RIVaccinationPeriod.Month15,
    RIVaccinationPeriod.Year14
  ]
}

export function riVaccinationPeriodsByDuration(duration) {
  const { years, months, weeks } = duration
  const periods = []

  // Allow vaccine from nine years on
  if (years >= 9) periods.push(RIVaccinationPeriod.Year14)
  if (months >= 15) periods.push(RIVaccinationPeriod.Month15)
  if (months >= 12) periods.push(RIVaccinationPeriod.Month12)
  if (months >= 9) periods.push(RIVaccinationPeriod.Month9)
  if (months >= 6) periods.push(RIVaccinationPeriod.Month6)
  if (weeks >= 14) periods.push(RIVaccinationPeriod.Week14)
  if (weeks >= 10) periods.push(RIVaccinationPeriod.Week10)
  if (weeks >= 6) periods.push(RIVaccinationPeriod.Week6)
  if (weeks >= 0) periods.push(RIVaccinationPeriod.Week0)

  return periods.reverse()
}

export function occupationCategoryChoices() {
  const { FrontlineWorker, HealthSector, Other } = OccupationCategory
  return [FrontlineWorker, HealthSector, Other].map((id) => ([id, id]))
}

export function pregnancyStatusChoices() {
  return [['preg', 'Pregnant'], ['notpreg', 'Not Pregnant']]
}

export function programChoices() {
  return [[ProgramID.COVID, 'COVID'], [ProgramID.RI, 'RI']]
}

export function stateChoices() {
  return Object.entries(States)
}

export function teamCategoryChoices() {
  return TeamCategoryOptions.map(option => [option.value, option.label])
}

export function vaccineChoices() {
  return VaccineIDSet.All.map((id) => ([id, VaccineDisplayNameMap[id]]))
}

export function yesNoChoices() {
  return [[true, 'Yes'], [false, 'No']]
}

export function yesNoUnknownChoices() {
  return ['Yes', 'No', 'Unknown'].map((label) => ([label.toUpperCase(), label]))
}
