<template>
  <div>
    <label
      v-if="labelPosition === 'above'"
      class="above ml-3"
    >
      {{ topLabel }}
    </label>
    <b-field
      :label-position="labelPosition"
      :class="customClass"
      :label="standardLabel"
      :type="!!error ? 'is-danger' : ''"
      :message="error"
    >
      <b-select
        ref="select"
        v-model="computedValue"
        v-bind="$attrs"
        expanded
        rounded
        :size="size"
        :placeholder="placeholder || label"
        @blur="$emit('blur', $event)"
        @focus="$emit('focus', $event)"
      >
        <option
          v-for="([val, label]) in options"
          :key="val"
          :value="val"
        >
          {{ label }}
        </option>
        <slot />
      </b-select>
    </b-field>
  </div>
</template>

<script>
import FormElementMixin from '../../mixins/component-mixin'

export default {
  name: 'ESelect',
  mixins: [FormElementMixin],
  props: {
    customClass: { type: String, default: 'mb-5' },
    labelPosition: { type: String, default: 'on-border' },
    size: { type: String, default: '' },
    value: {
      type: [String, Number, Boolean, Object, Array, Function, Date],
      default: null
    },
    options: {
      type: Array,
      default(props) {
        return []
      }
    }
  },
  data() {
    return {
      selected: this.value
    }
  },
  computed: {
    topLabel() {
      return this.labelPosition === 'above' ? this.label : ''
    },
    standardLabel() {
      return this.labelPosition !== 'above' ? this.label : ''
    },
    computedValue: {
      get() {
        return this.selected
      },
      set(value) {
        this.selected = value
        this.$emit('input', value)
      }
    }
  },
  watch: {
    /**
     * When v-model is changed:
     *   1. Set the selected option.
     */
    value(value) {
      this.selected = value
    }
  }
}
</script>

<style>
  label.above {
    font-size: 9pt;
    font-weight: bold;
    display: inline-block;
    line-height: 13px !important;
  }
</style>
