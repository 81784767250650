var render = function render(){var _vm=this,_c=_vm._self._c;return (!_vm.isEditMode)?_c('div',{staticClass:"column"},[_c('b-field',{attrs:{"grouped":"","label-position":"on-border"}},[_c('b-button',{directives:[{name:"show",rawName:"v-show",value:(!_vm.searchOnline),expression:"!searchOnline"}],attrs:{"type":"is-success","disabled":_vm.fetching,"label":`${_vm.fetching ? 'Fetching Recent Records ...' : 'Fetch Recent Records'}`},on:{"click":_vm.refreshRecords}})],1),_c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.searchOnline),expression:"!searchOnline"}],staticClass:"mb-4"},[_vm._m(0)]),(_vm.searchOnline)?_c('e-search-online',{attrs:{"entity-type":_vm.entityType,"search-term":_vm.searchTerm,"search-attributes":_vm.searchAttributes},on:{"cancel":_vm.cancelSearch,"complete":_vm.updateTable}}):_vm._e(),_c('vue-good-table',{attrs:{"columns":_vm.columns,"rows":_vm.records,"pagination-options":{
      allLabel: 'more',
      enabled: true,
      position: 'top',
      rowsPerPageLabel: 'Listing',
      infoFn: (params) => `Recent records...`,
    },"row-style-class":_vm.rowStyleClassFn,"search-options":{ enabled: true, placeholder: 'Search for Records...' }},on:{"on-row-click":_vm.onRowSelected,"on-search":_vm.onSearch}},[_vm._v(" > "),_c('div',{attrs:{"slot":"emptystate"},slot:"emptystate"},[(_vm.searchOnline)?_c('span',[_c('em',[_vm._v(" Search on DHIS Web using a super access to confirm that this record exists or not. ")])]):_c('span',[_vm._v(" Click on "),_c('strong',[_vm._v("Fetch Recent Records")]),_vm._v(" or Restart the App. ")])])])],1):_c('div',[_c('e-covid-form',{attrs:{"data":_vm.selectedRecord},on:{"go-back":function($event){_vm.selectedRecord = undefined},"submit":_vm.saveUpdatedRecord}})],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('strong',[_c('pre',[_vm._v("Search Online for more records.")])])
}]

export { render, staticRenderFns }