
<template>
  <div class="columns">
    <div
      v-if="isLoading"
      class="column mb-4"
    >
      <em>Searching for record on DHIS ...</em>
      <b-progress />
    </div>

    <e-select
      v-model="searchField"
      custom-class="mt-3 ml-2"
      label="Online search field"
      placeholder="Search by"
      :options="searchAttributes"
    />

    <div
      class="column buttons has-text-left"
    >
      <b-button
        type="is-success"
        label="Search Online"
        :disabled="!(searchField?.length)"
        @click="onSearchOnline"
      />

      <b-button
        label="Cancel"
        type="is-dark"
        @click="stopSearch"
      />
    </div>
  </div>
</template>
<script>

import { mapState } from 'vuex'

import { ESelect } from '../components/generic'
import { TrackedEntityTypeID } from '../constants/ids'
import { CovidRecord, RIRecord, TrackedEntity } from '../models'
import { getBasicAuthHeaders } from '../utils/auth'
import { fetchTrackedEntity } from '../utils/fetch'

export default {
  name: 'ESearchOnline',
  components: {
    ESelect
  },
  props: {
    searchTerm: { type: String, default: undefined },
    entityType: { type: String, default: TrackedEntityTypeID.COVID },
    searchAttributes: { type: Array, default() { return [] } }
  },
  emits: ['cancel', 'complete'],
  data() {
    return {
      isLoading: false,
      searchField: undefined
    }
  },
  computed: mapState(['auth', 'programId']),
  methods: {
    onSearchOnline() {
      if (this.searchTerm.length < 3) {
        this.$buefy.toast.open('Search term must be above 3 characters!')
        return
      }

      if (!navigator.onLine) {
        const message = 'You are offline. Searching external data requires Internet connection!'
        this.$buefy.toast.open(message)
        return
      }

      this.isLoading = true
      const config = {
        headers: getBasicAuthHeaders(this.auth.token),
        params: {
          fields: TrackedEntity.Fields.join(','),
          filter: `${this.searchField}:EQ:${this.searchTerm}`,
          ou: this.auth.ou,
          order: 'created:desc',
          ouMode: 'ACCESSIBLE',
          pageSize: this.pageSize,
          program: this.programId,
          totalPages: false
        }
      }

      fetchTrackedEntity(config)
        .then((data) => data.data.trackedEntityInstances)
        .then((rows) => (rows || []).map((r, index) => {
          const ent = TrackedEntity.fromEntityJSON(r, this.programId, this.entityType)
          const record = this.entityType === TrackedEntityTypeID.COVID
            ? CovidRecord.fromEntity(ent)
            : RIRecord.fromEntity(ent)

          record.__index = index
          return record
        }))
        .then((records) => {
          this.$buefy.dialog.alert({
            size: 'is-small',
            title: 'Online Search',
            message: `${records.length} record(s) matched`,
            onConfirm: () => { this.$emit('complete', records) }
          })
        })
        .catch((err) => console.error(`Online search failed. Error; ${err}`))
        .finally(() => {
          this.isLoading = false
        })
    },
    stopSearch() {
      this.$emit('cancel')
      this.searchField = undefined
    }
  }
}
</script>
