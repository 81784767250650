<template>
  <b-field
    label-position="on-border"
    :class="customClass"
    :label="label"
    :type="!!error ? 'is-danger' : ''"
    :message="error"
  >
    <b-datepicker
      ref="datepicker"
      v-bind="$attrs"
      expanded
      rounded
      trap-focus
      :placeholder="placeholder || label"
      :icon="icon ? icon : 'calendar-alt'"
      :min-date="minDate"
      :max-date="maxDate"
      :value="computedValue"
      @input="updateValue($event)"
    />
  </b-field>
</template>

<script>
import moment from 'moment'

import FormElementMixin from '../../mixins/component-mixin'

function parseDate(value) {
  const dateTime = moment(value, moment.ISO_8601)
  if (dateTime.isValid()) {
    return dateTime.toDate()
  }
  return undefined
}

export default {
  name: 'EDatePicker',
  mixins: [FormElementMixin],
  props: {
    icon: { type: String, default: 'calendar-alt' },
    maxDate: { type: [Date, String], default: undefined },
    minDate: { type: [Date, String], default: undefined },
    size: { type: String, default: '' },
    type: { type: String, default: 'text' },
    value: {
      type: [Array, Date, String],
      default: undefined
    }
  },
  data() {
    return {
      newValue: parseDate(this.value)
    }
  },
  computed: {
    computedValue: {
      get() {
        return this.newValue
      },
      set(value) {
        this.newValue = parseDate(value)
        this.$emit('input', parseDate(value))
      }
    }
  },
  watch: {
    value(value) {
      this.newValue = parseDate(value)
    }
  },
  methods: {
    updateValue(value) {
      this.computedValue = value
    }
  }
}
</script>
