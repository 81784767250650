import { AttributeID, DataElementID } from './ids'

export const AttributeMapping = {
  COVID: {
    ID: {},
    Field: {
      vaccinationNumber: { id: AttributeID.COVID.VaccinationNumber },
      age: { id: AttributeID.COVID.BirthDate }, // NOTE: age & birthDate share same field
      birthDate: { id: AttributeID.COVID.BirthDate },
      familyName: { id: AttributeID.COVID.FamilyName },
      otherName: { id: AttributeID.COVID.OtherName },
      email: { id: AttributeID.COVID.Email },
      gender: { id: AttributeID.COVID.Gender },
      hasAllergies: { id: AttributeID.COVID.HasAllergies },
      idNumber: { id: AttributeID.COVID.IDNumber },
      idType: { id: AttributeID.COVID.IDType },
      isAgeEstimated: { id: AttributeID.COVID.IsAgeEstimated },
      occupation: { id: AttributeID.COVID.Occupation },
      phoneNumber: { id: AttributeID.COVID.PhoneNumber },
      pregnancyStatus: { id: AttributeID.COVID.PregnancyStatus },
      residentialAddress: { id: AttributeID.COVID.ResidentialAddress }
    }
  },
  RI: {
    ID: {},
    Field: {
      vaccinationNumber: { id: AttributeID.RI.VaccinationNumber },
      birthDate: { id: AttributeID.RI.BirthDate },
      firstName: { id: AttributeID.RI.FirstName },
      surname: { id: AttributeID.RI.Surname },
      gender: { id: AttributeID.RI.Gender },
      careGiverFirstName: { id: AttributeID.RI.CareGiverFirstName },
      careGiverSurname: { id: AttributeID.RI.CareGiverSurname },
      careGiverPhoneNumber: { id: AttributeID.RI.CareGiverPhoneNumber },
      address: { id: AttributeID.RI.Address },
      settlement: { id: AttributeID.RI.Settlement },
      hasVeryHighTemperatureOrSick: { id: AttributeID.RI.hasVeryHighTemperatureOrSick }
    }
  }
}

// IDs to fields attribute mapping
Object
  .keys(AttributeMapping)
  .map((program) => AttributeMapping[program])
  .forEach((mapping) => Object.keys(mapping.Field).forEach((field) => {
    mapping.ID[mapping.Field[field].id] = field
  }))

export const DataElementMapping = {
  COVID: {
    ID: {},
    Field: {
      doseNumber: { id: DataElementID.COVID.DoseNumber },
      outsideNigeria: { id: DataElementID.COVID.OutsideNigeria },
      preSelectedBySystem: { id: DataElementID.COVID.PreSelectedBySystem },
      nextDoseSuggestedDate: { id: DataElementID.COVID.NextDoseSuggestedDate },
      qrCode: { id: DataElementID.COVID.QRCode },
      vaccineType: { id: DataElementID.COVID.VaccineType },
      vaccinated: { id: DataElementID.COVID.Vaccinated },
      vaccinated2ndDose: { id: DataElementID.COVID.Vaccinated2ndDose },
      vaccineBatchNumber: { id: DataElementID.COVID.VaccineBatchNumber },
      verified: { id: DataElementID.COVID.Verified }
    }
  },
  RI: {
    ID: {},
    Field: {
      // event 1
      birthAttendant: { id: DataElementID.RI.BirthAttendant },
      birthFacility: { id: DataElementID.RI.BirthFacility },
      birthSettlement: { id: DataElementID.RI.BirthSettlement },
      birthType: { id: DataElementID.RI.BirthType },
      birthWeight: { id: DataElementID.RI.BirthWeight },
      deliveryMode: { id: DataElementID.RI.DeliveryMode },
      deliveryPlace: { id: DataElementID.RI.DeliveryPlace },
      gestationalAge: { id: DataElementID.RI.GestationalAge },
      parity: { id: DataElementID.RI.Parity },

      // event 2
      birthRegistrationDate: { id: DataElementID.RI.BirthRegistrationDate },
      hasCollectedBirthCertificate: { id: DataElementID.RI.HasCollectedBirthCertificate },
      vaccinationLocation: { id: DataElementID.RI.VaccinationLocation },
      updateHIVStatus: { id: DataElementID.RI.UpdateHIVStatus }, // HINT: no longer required
      hasVaccineAllergy: { id: DataElementID.RI.HasVaccineAllergy },
      hasImmunodeficiencyOrHIV: { id: DataElementID.RI.HasImmunodeficiencyOrHIV },
      hasHighTemperatureOrVerySick: { id: DataElementID.RI.HasHighTemperatureOrVerySick },

      antigenBCG: { id: DataElementID.RI.AntigenBCG },
      antigenBOPV0: { id: DataElementID.RI.AntigenBOPV0 },
      antigenHEPB0: { id: DataElementID.RI.AntigenHEPB0 },

      antigenPENTA1: { id: DataElementID.RI.AntigenPENTA1 },
      antigenPCV1: { id: DataElementID.RI.AntigenPCV1 },
      antigenBOPV1: { id: DataElementID.RI.AntigenBOPV1 },
      antigenROTA1: { id: DataElementID.RI.AntigenROTA1 },
      antigenIPV1: { id: DataElementID.RI.AntigenIPV1 },

      antigenPENTA2: { id: DataElementID.RI.AntigenPENTA2 },
      antigenPCV2: { id: DataElementID.RI.AntigenPCV2 },
      antigenBOPV2: { id: DataElementID.RI.AntigenBOPV2 },
      antigenROTA2: { id: DataElementID.RI.AntigenROTA2 },

      antigenPENTA3: { id: DataElementID.RI.AntigenPENTA3 },
      antigenPCV3: { id: DataElementID.RI.AntigenPCV3 },
      antigenBOPV3: { id: DataElementID.RI.AntigenBOPV3 },
      antigenROTA3: { id: DataElementID.RI.AntigenROTA3 },
      antigenIPV2: { id: DataElementID.RI.AntigenIPV2 },

      antigenVitaminA1: { id: DataElementID.RI.AntigenVitaminA1 },

      antigenMeasles1: { id: DataElementID.RI.AntigenMeasles1 },
      antigenYellowFever: { id: DataElementID.RI.AntigenYellowFever },
      antigenMeningitis: { id: DataElementID.RI.AntigenMeningitis },

      antigenVitaminA2: { id: DataElementID.RI.AntigenVitaminA2 },

      antigenMeasles2: { id: DataElementID.RI.AntigenMeasles2 },

      antigenHPV1: { id: DataElementID.RI.AntigenHPV1 }
    }
  }
}

// IDs to fields dataElement mapping
Object
  .keys(DataElementMapping)
  .map((program) => DataElementMapping[program])
  .forEach((mapping) => Object.keys(mapping.Field).forEach((field) => {
    mapping.ID[mapping.Field[field].id] = field
  }))
