<template>
  <div
    v-if="!isEditMode"
    class="column"
  >
    <b-field
      grouped
      label-position="on-border"
    >
      <b-button
        v-show="!searchOnline"
        type="is-success"
        :disabled="fetching"
        :label="`${fetching ? 'Fetching Recent Records ...' : 'Fetch Recent Records'}`"
        @click="refreshRecords"
      />
    </b-field>

    <div
      v-show="!searchOnline"
      class="mb-4"
    >
      <strong>
        <pre>Search Online for more records.</pre>
      </strong>
    </div>

    <e-search-online
      v-if="searchOnline"
      :entity-type="entityType"
      :search-term="searchTerm"
      :search-attributes="searchAttributes"
      @cancel="cancelSearch"
      @complete="updateTable"
    />

    <vue-good-table
      :columns="columns"
      :rows="records"
      :pagination-options="{
        allLabel: 'more',
        enabled: true,
        position: 'top',
        rowsPerPageLabel: 'Listing',
        infoFn: (params) => `Recent records...`,
      }"
      :row-style-class="rowStyleClassFn"
      :search-options="{ enabled: true, placeholder: 'Search for Records...' }"
      @on-row-click="onRowSelected"
      @on-search="onSearch"
    >
      >
      <div slot="emptystate">
        <span v-if="searchOnline">
          <em>
            Search on DHIS Web using a super access to confirm that this
            record exists or not.
          </em>
        </span>
        <span v-else>
          Click on <strong>Fetch Recent Records</strong> or Restart the App.
        </span>
      </div>
    </vue-good-table>
  </div>

  <div v-else>
    <e-covid-form
      :data="selectedRecord"
      @go-back="selectedRecord = undefined"
      @submit="saveUpdatedRecord"
    />
  </div>
</template>

<script>
import { mapMutations, mapState } from 'vuex'

import ESearchOnline from '../components/search-online.vue'
import { AttributeID, TrackedEntityTypeID } from '../constants/ids'
import ECovidForm from '../containers/covid-form'
import { CovidRecord, isCovidRecord } from '../models'
import { fetchCovidTrackedEntity } from '../services/dhis'
import { getBasicAuthHeaders } from '../utils/auth'

const TableColumns = [
  { field: (obj) => `${obj.profile.familyName} ${obj.profile.otherName}`, label: 'Full Name' },
  { field: 'profile.vaccinationNumber', label: 'Vaccination No' },
  { field: 'profile.phoneNumber', label: 'Phone' },
  { field: (obj) => obj?.entity?.created?.substring(0, 10), label: 'Created' }
]

export default {
  components: {
    ECovidForm,
    ESearchOnline
  },
  data() {
    return {
      columns: Object.freeze(TableColumns),

      // flags
      fetching: false,
      isLoading: false,
      searchAttributes: [
        [AttributeID.COVID.VaccinationNumber, 'Vaccination Number'],
        [AttributeID.COVID.PhoneNumber, 'Phone Number']
      ],
      searchOnline: false,
      searchField: undefined,
      searchTerm: undefined,
      searchRecords: undefined,
      selectedRecord: undefined
    }
  },
  computed: {
    ...mapState([
      'auth',
      'covidRecords',
      'pageSize',
      'programId',
      'syncedRecords',
      'upsertRecords'
    ]),
    entityType() { return TrackedEntityTypeID.COVID },
    isEditMode() { return this.selectedRecord !== undefined },
    records() {
      return this.searchRecords?.length
        ? this.searchRecords
        : this.offlineRecords
    },
    offlineRecords() {
      // collect synced records from store syncedRecords
      const syncedNumbers = this.syncedRecords.map((r) => r.profile.vaccinationNumber)
      const syncedRecords = this.syncedRecords
        .filter(isCovidRecord)
        .map((r) => new CovidRecord(r))
        .filter((r) => r.isNew())
        .sort((x, y) => x.timestamp > y.timestamp ? -1 : 1) // descending order
        .map((record) => {
          record.__synced = true
          return record
        })

      // collect new/updated records from store upsertRecords
      const newRecords = Object.values(this.upsertRecords)
        .filter(isCovidRecord)
        .map((r) => new CovidRecord(r))
        .filter((r) => r.isNew())
        .sort((x, y) => x.timestamp > y.timestamp ? -1 : 1) // descending order

      // collect recent records fetched from DHIS2
      const recentRecords = this.covidRecords
        .map((entity) => {
          const record = CovidRecord.fromEntity(entity)
          if (syncedNumbers.includes(record.profile.vaccinationNumber)) {
            record.__synced = true
          }

          record.__index = entity.__index
          return record
        })

      return [...syncedRecords, ...newRecords, ...recentRecords]
    }
  },
  mounted() {
    if (!this.covidRecords.length) {
      this.fetchData()
    }
  },
  methods: {
    ...mapMutations(['setUpsertRecords']),
    fetchData() {
      this.isLoading = true
      const context = {
        auth: this.auth,
        headers: getBasicAuthHeaders(this.auth.token),
        pageSize: this.pageSize,
        programId: this.programId
      }

      fetchCovidTrackedEntity(context).finally(() => {
        this.isLoading = false
      })
    },
    onRowSelected(params) {
      this.selectedRecord = new CovidRecord(params.row)
      this.selectedRecord.__index = params.row.__index
    },
    refreshRecords() {
      if (navigator.onLine) {
        this.$buefy.toast.open('Fetching few recent records...')
        this.fetchData()
      } else {
        console.log('Internet is required to Fetch Records!')
      }
    },
    rowStyleClassFn(row) {
      return row.__synced ? 'synced' : ''
    },
    cancelSearch() {
      this.searchField = undefined
      this.searchOnline = false
      this.searchTerm = undefined
      this.searchRecords = undefined
    },
    onSearch(params) {
      this.searchOnline = params.rowCount === 0
      this.searchTerm = params.searchTerm
    },
    updateTable(records) {
      this.searchRecords = records
    },
    saveUpdatedRecord(updatedRecord) {
      this.setUpsertRecords(updatedRecord)

      const vaccinationNumber = updatedRecord.getVaccinationNumber()
      this.$buefy.dialog.alert({
        size: 'is-small',
        message: `Record saved offline. Vaccination Number: ${vaccinationNumber}`,
        onConfirm: () => { this.selectedRecord = undefined }
      })
    }
  }
}
</script>

<style>
  .synced {
    background-color: whitesmoke;
    pointer-events: none;
  }
  .vgt-wrap * {
    font-size: 14px;
  }
  .vgt-wrap__footer * {
    font-size: 14px !important;
  }
  body {
    margin: 0; /* Reset default margin */
    min-height: 100vh;
  }
  iframe {
    display: block;
    background: #000;
    border: none; /* Reset default border */
    height: 100vh; /* Viewport-relative units */
    width: 100vw;
  }
</style>
