<template>
  <b-collapse
    animation="slide"
    aria-id="contentIdForAlly3"
    class="card mb-5"
  >
    <template #trigger="props">
      <div
        class="card-header"
        role="button"
        aria-controls="contentIdForAlly3"
        :aria-expanded="props.opens"
      >
        <p class="card-header-title mb-0">
          {{ title }}
        </p>
        <a class="card-header-icon">
          <b-icon :icon="props.opens ? 'menu-down' : 'menu-up'" />
        </a>
      </div>
    </template>

    <div class="card-content py-3">
      <div class="content has-text-left m-0">
        <slot />
      </div>
    </div>
  </b-collapse>
</template>

<script>
export default {
  name: 'EContentCard',
  props: {
    open: { type: Boolean, default: false },
    title: { type: String, default: '' }
  },
  data() {
    return {}
  }
}
</script>
