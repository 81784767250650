import 'buefy/dist/buefy.css'
import './registerServiceWorker'
import 'vue-good-table/dist/vue-good-table.css'

import Buefy from 'buefy'
import Vue from 'vue'
import VueGoodTablePlugin from 'vue-good-table'
import VueOffline from 'vue-offline'

import App from './App.vue'
import router from './router'
import store from './store'

Vue.use(VueGoodTablePlugin)
Vue.use(VueOffline)

Vue.use(Buefy, {
  defaultIconPack: 'fas'
})

Vue.use(Buefy, {
  defaultDateFormatter: (date) => date.toISOString().split('T')[0]
})

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
