<template>
  <div>
    <b-field
      grouped
      group-multiline
      label-position="on-border"
    >
      <div class="control">
        <b-button
          label="Go Back"
          type="is-dark"
          @click="$router.go(-1)"
        />
      </div>
    </b-field>
    <br>

    <strong> Scan QR Code to Verify Record </strong> <br>
    <span> {{ verifyQr }} </span>

    <b-button
      label="Show Record"
      @click="showFrame = true"
    />

    <iframe
      v-if="showFrame"
      :src="verifyQr"
      title="verifyQr"
    />

    <qrcode-stream
      v-else
      @init="onQrInit"
      @decode="onQrDecode"
    />
  </div>
</template>

<script>
import { QrcodeStream } from 'vue-qrcode-reader'

import { DataElementID } from '../constants/ids'
import QRCodeMixin from '../mixins/qrcode-mixin'

export default {
  components: {
    QrcodeStream
  },
  mixins: [QRCodeMixin],
  data () {
    return {
      showBatchScanner: false,
      showFrame: false,
      verifyQr: undefined
    }
  },
  watch: {
    qrcode(val) {
      if (this.selected) {
        this.selected[DataElementID.QRCode] = val
      }
    },
    verifyQr(val) {
      if (this.selected) {
        this.selected[DataElementID.QRCode] = val
      }
    }
  },
  methods: {
    onQrDecode(decodedString) {
      this.verifyQr = decodedString
      if (this.verifyQr.includes('verify')) {
        this.$buefy.dialog.alert('QR code scan is successful!')
      }
    }
  }
}
</script>
