<template>
  <section class="is-desktop is-centered">
    <div class="columns is-flex">
      <b-button
        class="ml-3"
        label="Go Back"
        type="is-dark"
        @click="$router.push({ name: 'home' })"
      />

      <div class="has-text-centered is-flex-grow-1">
        <b-button
          class="mr-2"
          type="is-success"
          label="Sync Records"
          :disabled="!selectedRows?.length"
          @click="() => syncRecords(false)"
        />

        <b-button
          label="Import/Export"
          @click="showExport = !showExport"
        />
      </div>
    </div>

    <div v-show="showExport">
      <div class="row has-text-centered mt-3">
        <b-button
          label="Download Offline Data"
          @click="exportOfflineRecords"
        />
      </div>
      <div class="row has-text-centered mt-3 mb-3">
        Import Offline Backup:
        <input
          ref="myFile"
          type="file"
          @change="importData"
        >
      </div>
    </div>

    <div class="my-3">
      <strong> Offline Records </strong>
    </div>

    <vue-good-table
      v-if="showTable"
      :columns="columns"
      :rows="records"
      :select-options="{ enabled: true }"
      :search-options="{ enabled: true, placeholder: 'Filter by Name / Vaccination No' }"
      @on-selected-rows-change="selectionChanged"
    >
      <div slot="table-actions">
        <b-button
          v-show="selectedRows.length"
          class="mr-2"
          label="Remove Offline Records"
          size="is-small"
          type="is-danger"
          @click="deleteRows()"
        />
      </div>
    </vue-good-table>
  </section>
</template>

<script>
import { mapMutations, mapState } from 'vuex'

import { CovidRecord, isCovidRecord, RIRecord } from '../models'
import { handleSyncEvent } from '../services/dhis'

const TableColumns = [
  {
    field: (obj) => isCovidRecord(obj)
      ? `${obj.profile.familyName}, ${obj.profile.otherName}`
      : `${obj.profile.surname}, ${obj.profile.firstName}`,
    label: 'Full Name'
  },
  { field: 'profile.vaccinationNumber', label: 'Vaccination No' },
  { field: (obj) => !obj.entity?.trackedEntityInstance ? 'New' : 'Updated', label: 'Status' },
  { field: (obj) => isCovidRecord(obj) ? 'COVID' : 'RI', label: 'Type' }
]

export default {
  data() {
    return {
      columns: Object.freeze(TableColumns),
      selectedRows: [],
      showExport: false,
      showTable: true
    }
  },
  computed: {
    ...mapState(['auth', 'programId', 'upsertRecords']),
    records () {
      return Object
        .values(this.upsertRecords)
        .map((r) => isCovidRecord(r) ? new CovidRecord(r) : new RIRecord(r))
    }
  },
  methods: {
    ...mapMutations(['removeUpsertRecords', 'setUpsertRecords']),
    selectionChanged (params) {
      this.selectedRows = params.selectedRows
    },
    deleteRows () {
      this.$buefy.dialog.confirm({
        size: 'is-small',
        title: 'Delete Records',
        message: 'Are you sure you want to delete selected offline records?',
        onConfirm: () => {
          const vaccinationNumbers = this.selectedRows.map((r) => r.profile.vaccinationNumber)
          this.removeUpsertRecords({ vaccinationNumbers, synced: false })
        }
      })
    },
    exportOfflineRecords () {
      this.$buefy.toast.open('Downloading offline records data')
      const offlineRecordJSON = JSON.stringify(this.upsertRecords)
      const offlineRecordData = btoa(offlineRecordJSON)

      const offlineRecordsBlob = new Blob([offlineRecordData], { type: 'text/plain' })

      const el = document.createElement('a')
      el.download = 'emid-offline-records.backup'
      el.href = window.URL.createObjectURL(offlineRecordsBlob)
      el.dataset.downloadurl = ['text/json', el.download, el.href].join(':')

      const event = new Event('click', { bubbles: true, cancelable: false })
      el.dispatchEvent(event)
    },
    importData() {
      this.$buefy.toast.open('Offline records data import in progress')

      this.showTable = false
      const file = this.$refs.myFile.files[0]

      const reader = new FileReader()
      reader.readAsText(file, 'UTF-8')
      reader.onload = (evt) => {
        const decodedData = atob(evt.target.result)
        const jsonData = JSON.parse(decodedData)

        Object.values(jsonData)
          .map((record) => new CovidRecord(record))
          .forEach((data) => this.setUpsertRecords(data))
      }

      // @TODO: Make this auto reload table
      reader.onerror = (evt) => {
        console.error(evt)
      }
    },
    syncRecords(quiet = false) {
      const context = {
        quiet,
        auth: this.auth,
        online: navigator.onLine,
        programId: this.programId,
        routeName: this.$route.name
      }

      handleSyncEvent(context, this.selectedRows)
    }
  }
}
</script>
