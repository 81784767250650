<template>
  <section class="layout">
    <e-strip />
    <e-navbar v-if="auth.token" />
    <div class="content mx-5">
      <router-view />
    </div>

    <e-footer />
    <e-strip />
  </section>
</template>

<script>
import { mapState } from 'vuex'

import EFooter from '../components/footer'
import ENavbar from '../components/navbar'
import EStrip from '../components/strip'

export default {
  name: 'Layout',
  components: {
    EStrip,
    ENavbar,
    EFooter
  },
  computed: mapState(['auth'])
}
</script>

<style>
  .layout {
    display: flex;
    flex-direction: column;

    min-height: 100vh;
  }
  .content {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    margin: auto 20px;
  }
</style>
