/**
 * DHIS2 disease identification numbers
 */
export const DiseaseID = {
  HeartDisease: 'vaElIVDfH9q',
  LungDisease: 'C0Bony47eKp',
  DiabetesComplications: 'ZE7eswg1EIj',
  Other: 'TRUE_ONLY'
}

/**
 * Mapping of disease identification numbers to appropriate display name.
 */
export const DiseaseDisplayNameMap = {
  [DiseaseID.HeartDisease]: 'Heart Disease',
  [DiseaseID.LungDisease]: 'Lung Disease',
  [DiseaseID.DiabetesComplications]: 'Diabetes/Diabetic Complications',
  [DiseaseID.Other]: 'Other'
}

export const BirthAttendant = {
  ChewCho: 'CHEW/CHOx',
  MidWife: 'MID-WIFE',
  Nurse: 'NURSE',
  NurseMidWife: 'NURSEMID-WIFE',
  Physician: 'PHYSICIAN',
  NotStated: 'NOT_STATED',
  Other: 'OTHER_PARAMEDICAL_PERSONNEL'
}

export const BirthAttendantDisplayNameMap = {
  [BirthAttendant.ChewCho]: 'Community Health (Extension Worker / Officer)',
  [BirthAttendant.MidWife]: 'Midwife',
  [BirthAttendant.Nurse]: 'Nurse',
  [BirthAttendant.NurseMidWife]: 'Nurse / Midwife',
  [BirthAttendant.Physician]: 'Physician',
  [BirthAttendant.NotStated]: 'Not Stated',
  [BirthAttendant.Other]: 'Other Paramedical Personnel'
}

/**
 * Birth place
 */
export const DeliveryPlace = {
  Home: 'HOME',
  Hospital: 'HOSPITAL',
  HealthFacility: 'HEALTH_FACILITY',
  Unknown: 'UNKNOWN'
}

export const DeliveryPlaceDisplayNameMap = {
  [DeliveryPlace.Home]: 'Home',
  [DeliveryPlace.Hospital]: 'Hospital',
  [DeliveryPlace.HealthFacility]: 'Health Facility',
  [DeliveryPlace.Unknown]: 'Unknown'
}

export const BirthType = {
  Singleton: 'SINGLETON',
  Twin: 'TWIN',
  Triplet: 'TRIPLET',
  Other: 'OTHER',
  Unknown: 'UNKNOWN'
}

export const DeliveryMode = {
  Normal: 'NORMALORVAGINAL',
  Forceps: 'FORCEPS',
  Vacuum: 'VACUUM',
  CSection: 'C-SECTION',
  Unknown: 'UNKNOWN'
}

export const DeliveryModeDisplayNameMap = {
  [DeliveryMode.CSection]: 'C-Section',
  [DeliveryMode.Forceps]: 'Forceps',
  [DeliveryMode.Normal]: 'Normal / Vaginal',
  [DeliveryMode.Vacuum]: 'Vacuum',
  [DeliveryMode.Unknown]: 'Unknown'
}

/**
 * Gender types
 */
export const Gender = {
  Female: 'FEMALE',
  Male: 'MALE'
}

/**
 * Occupation categories
 */
export const OccupationCategory = {
  FrontlineWorker: 'Frontline Worker',
  HealthSector: 'Health Sector',
  Other: 'Other'
}

/**
 * Identification Types
 */
export const IDType = {
  DriverLicense: 'dl',
  IntlPassport: 'ip',
  NationalID: 'nin',
  VotersCard: 'vc',
  NotAvailable: 'ot'
}

/**
 * Mapping of identification types to a corresponding display label/name.
 */
export const IDTypeDisplayNameMap = {
  [IDType.DriverLicense]: "Driver's License",
  [IDType.IntlPassport]: 'International Passport',
  [IDType.NationalID]: 'National ID (NIN)',
  [IDType.VotersCard]: 'Voters Card',
  [IDType.NotAvailable]: 'Not Available'
}

/**
 * The order to display identification types to the user.
 */
export const IDTypeOrderList = [
  IDType.NationalID,
  IDType.DriverLicense,
  IDType.IntlPassport,
  IDType.VotersCard,
  IDType.NotAvailable
]

export const RIVaccinationLocation = {
  OutsideDistrict: '04',
  OutsideCountry: '05',
  Unknown: '06',
  OutreachCampaign: '07',
  MassCampaign: '08',
  DistrictFacility: '09',
  OtherDistrictFacility: '10'
}

export const RIVaccinationLocationDisplayNameMap = {
  [RIVaccinationLocation.OutsideDistrict]: 'Outside District',
  [RIVaccinationLocation.OutsideCountry]: 'Outside the Country',
  [RIVaccinationLocation.OutreachCampaign]: 'Outreach Campaign',
  [RIVaccinationLocation.MassCampaign]: 'Mass Campaign',
  [RIVaccinationLocation.DistrictFacility]: 'District Facility',
  [RIVaccinationLocation.OtherDistrictFacility]: 'Other Facility in District',
  [RIVaccinationLocation.Unknown]: 'Unknown'
}

export const RIVaccinationPeriod = {
  Week0: 'Week0',
  Week6: 'Week6',
  Week10: 'Week10',
  Week14: 'Week14',
  Month6: 'Month6',
  Month9: 'Month9',
  Month12: 'Month12',
  Month15: 'Month15',
  Year14: 'Year14'
}

export const RIVaccinationPeriodDisplayNameMap = {
  [RIVaccinationPeriod.Week0]: 'At Birth Antigens',
  [RIVaccinationPeriod.Week6]: '6 Weeks Antigens',
  [RIVaccinationPeriod.Week10]: '10 Weeks Antigens',
  [RIVaccinationPeriod.Week14]: '14 Weeks Antigens',
  [RIVaccinationPeriod.Month6]: '6 Months Antigens',
  [RIVaccinationPeriod.Month9]: '9 Months Antigens',
  [RIVaccinationPeriod.Month12]: '12 Months Antigens',
  [RIVaccinationPeriod.Month15]: '15 Months Antigens',
  [RIVaccinationPeriod.Year14]: '14 Years Antigens'
}

export const ImmunizationVaccineDisplayNameMap = {
  BCG: 'BCG 0.05ml',
  BOPV0: 'bOPV 0',
  HEPB0: 'Hepatitis B 0',
  IPV1: 'IPV 1',
  IPV2: 'IPV 2',
  BOPV1: 'bOPV 1',
  BOPV2: 'bOPV 2',
  BOPV3: 'bOPV 3',
  Measles1: 'Measles 1',
  Measles2: 'Measles 2',
  Meningitis: 'Meningitis',
  PENTA1: 'Penta (DPT, Hep B & Hib) 1',
  PENTA2: 'Penta (DPT, Hep B & Hib) 2',
  PENTA3: 'Penta (DPT, Hep B & Hib) 3',
  PCV1: 'PCV (Pneumococcal Conjugate Vaccine) 1',
  PCV2: 'PCV (Pneumococcal Conjugate Vaccine) 2',
  PCV3: 'PCV (Pneumococcal Conjugate Vaccine) 3',
  ROTA1: 'Rota 1',
  ROTA2: 'Rota 2',
  ROTA3: 'Rota 3',
  VitaminA1: 'Vitamin A1',
  VitaminA2: 'Vitamin A2',
  YellowFever: 'Yellow Fever',
  HPV1: 'HPV 1'
}

export const VaccineAllergy = {
  No: 'NO',
  Measles: 'MEASLES',
  RotaVirus: 'ROTAVIRUS',
  Other: 'OTHER'
}
