<template>
  <div id="app">
    <b-notification
      v-if="updateExists"
      type="is-light"
      aria-close-label="Close notification"
      role="alert"
      class="column"
    >
      <a @click="refreshApp">
        Click here to update App with recent changes.
      </a>
    </b-notification>

    <Layout />
  </div>
</template>

<script>
import Layout from './containers/layout'
import update from './mixins/update'

export default {
  name: 'App',
  components: {
    Layout
  },
  mixins: [update]
}
</script>

<style>
  #app {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: #2c3e50;
    font-size: 16px;
    min-height: 100vh !important;
  }
</style>
