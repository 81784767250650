<template>
  <section class="has-text-centered">
    <div class="columns">
      <e-notification
        v-if="isOffline"
        type="is-danger is-light"
        has-icon
      >
        You are Offline!
      </e-notification>
    </div>
    <div class="columns">
      <e-notification
        :type="offlineRecordsCount !== 'NO' ? 'is-danger' : ''"
      >
        You have <strong> {{ offlineRecordsCount }} </strong> offline records.
      </e-notification>
    </div>
    <div class="columns">
      <e-notification
        v-if="deferredPrompt"
        type="is-info is-light"
      >
        <a @click="install">Add N-eMID App to your Home Screen</a>
      </e-notification>
    </div>

    <div class="nav-buttons columns">
      <div class="column">
        <b-button
          rounded
          label="Create Record"
          type="is-success"
          size="is-large"
          @click="goto('create')"
        />
      </div>

      <div class="column">
        <b-button
          rounded
          label="List Records"
          type="is-success"
          size="is-large"
          @click="goto('recent')"
        />
      </div>
    </div>

    <div
      class="nav-buttons columns"
    >
      <div class="column">
        <b-button
          rounded
          :disabled="!showVerifyOfflineButtons"
          label="Verify Record"
          type="is-success"
          size="is-large"
          @click="goto('verify')"
        />
      </div>

      <div class="column">
        <b-button
          rounded
          label="Offline Records"
          type="is-success"
          size="is-large"
          @click="goto('pending')"
        />
      </div>
    </div>
  </section>
</template>

<script>
import { mapState } from 'vuex'

import ENotification from '../components/notification'
import { ProgramID } from '../constants/ids'

export default {
  name: 'Dashboard',
  components: {
    ENotification
  },
  data () {
    return {
      deferredPrompt: null
    }
  },
  computed: {
    ...mapState(['programId', 'upsertRecords']),
    offlineRecordsCount() {
      const count = Object.keys(this.upsertRecords).length
      return count > 0 ? count : 'NO'
    },
    showVerifyOfflineButtons() {
      return this.programId === ProgramID.COVID
    }
  },
  created() {
    window.addEventListener('beforeinstallprompt', (e) => {
      e.preventDefault()
      // Stash the event so it can be triggered later.
      this.deferredPrompt = e
    })
    window.addEventListener('appinstalled', () => {
      this.deferredPrompt = null
    })
  },
  methods: {
    async dismiss () {
      this.deferredPrompt = null
    },
    async install () {
      this.deferredPrompt.prompt()
    },
    goto(routeName) {
      if (this.programId === ProgramID.RI && !['home', 'pending'].includes(routeName)) {
        routeName = `${routeName}-ri`
      }

      if (this.$route.name !== routeName) {
        this.$router.push({ name: routeName })
      }
    },
    testApp () {
      return window.location.hostname.includes('test')
    }
  }
}
</script>

<style>
  @media (min-width: 800px) {
    .nav-buttons {
      margin: 0 auto;
      width: 700px;
      max-width: 700px;
    }
  }
</style>
