<template>
  <div v-if="!isEditMode">
    <div class="buttons">
      <b-button
        v-show="!searchOnline"
        label="Fetch Recent Records"
        type="is-success"
        :disabled="isLoading"
        @click="fetchData"
      />
    </div>

    <e-search-online
      v-if="searchOnline"
      :entity-type="entityType"
      :search-term="searchTerm"
      :search-attributes="searchAttributes"
      @complete="updateTable"
      @cancel="cancelOnlineSearch"
    />

    <vue-good-table
      :columns="columns"
      :rows="records"
      :pagination-options="{
        allLabel: 'more',
        enabled: true,
        position: 'top',
        rowsPerPageLabel: 'Listing',
        infoFn: (params) => `Recent records...`,
      }"
      :row-style-class="rowStyleClassFn"
      :search-options="{ enabled: true, placeholder: 'Search for Records...' }"
      @on-row-click="onRowSelected"
      @on-search="onSearch"
    >
      <div slot="emptystate">
        <span>Click on <strong> Fetch Recent Records </strong></span>
      </div>
    </vue-good-table>
  </div>

  <div v-else>
    <e-ri-form
      :data="selectedRecord"
      @go-back="selectedRecord = undefined"
      @submit="saveUpdatedRecord"
    />
  </div>
</template>

<script>
import { mapMutations, mapState } from 'vuex'

import ESearchOnline from '../components/search-online.vue'
import { AttributeID, TrackedEntityTypeID } from '../constants/ids'
import ERiForm from '../containers/ri-form'
import { isRIRecord, RIRecord } from '../models'
import { fetchRITrackedEntity } from '../services/dhis'
import { getBasicAuthHeaders } from '../utils/auth'

const TableColumns = [
  { field: 'profile.firstName', label: 'First Name' },
  { field: 'profile.surname', label: 'Surname' },
  {
    field: (obj) => obj.entity.created.substring(0, 10), // extract yyyy-MM-dd from date
    type: 'date',
    dateInputFormat: 'yyyy-MM-dd',
    dateOutputFormat: 'dd-MM-yyyy',
    label: 'Created'
  }
]

export default {
  components: {
    ERiForm,
    ESearchOnline
  },
  data() {
    return {
      columns: Object.freeze(TableColumns),
      searchAttributes: [
        [AttributeID.RI.VaccinationNumber, 'Vaccination Number'],
        [AttributeID.RI.FirstName, 'First Name'],
        [AttributeID.RI.Surname, 'Surname']
      ],
      searchRecords: undefined,
      searchOnline: false,
      searchTerm: undefined,
      selectedRecord: undefined,
      isLoading: false
    }
  },
  computed: {
    ...mapState(['auth', 'pageSize', 'programId', 'riRecords', 'syncedRecords', 'upsertRecords']),
    entityType() { return TrackedEntityTypeID.RI },
    isEditMode() { return this.selectedRecord !== undefined },
    records() {
      if (this.searchRecords?.length > 0) {
        return this.searchRecords
      }
      // collect synced records from store syncedRecords
      const syncedNumbers = this.syncedRecords.map((r) => r.profile.vaccinationNumber)
      const syncedRecords = this.syncedRecords
        .filter(isRIRecord)
        .map((r) => new RIRecord(r))
        .filter((r) => r.isNew())
        .sort((x, y) => x.timestamp > y.timestamp ? -1 : 1) // descending order
        .map((record) => {
          record.__synced = true
          return record
        })

      // collect new/updated records from store upsertRecords
      const newRecords = Object.values(this.upsertRecords)
        .filter(isRIRecord)
        .map((r) => new RIRecord(r))
        .filter((r) => r.isNew())
        .sort((x, y) => x.timestamp > y.timestamp ? -1 : 1) // descending order

      // collect recent records fetched from DHIS2
      const recentRecords = this.riRecords
        .map((entity) => {
          const record = RIRecord.fromEntity(entity)
          if (syncedNumbers.includes(record.profile.vaccinationNumber)) {
            record.__synced = true
          }

          record.__index = entity.__index
          return record
        })

      return [...syncedRecords, ...newRecords, ...recentRecords]
    }
  },
  mounted() {
    if (!this.riRecords.length) {
      this.fetchData()
    }
  },
  methods: {
    ...mapMutations(['setUpsertRecords']),
    cancelOnlineSearch() {
      this.searchOnline = false
    },
    fetchData() {
      this.isLoading = true
      const context = {
        auth: this.auth,
        headers: getBasicAuthHeaders(this.auth.token),
        pageSize: this.pageSize,
        programId: this.programId
      }

      fetchRITrackedEntity(context).finally(() => {
        this.isLoading = false
      })
    },
    rowStyleClassFn(row) {
      return row.__synced ? 'synced' : ''
    },
    onRowSelected(params) {
      this.selectedRecord = RIRecord.forUpdate(params.row)
      this.selectedRecord.__index = params.row.__index
    },
    onSearch(params) {
      this.searchOnline = params.rowCount === 0
      this.searchTerm = params.searchTerm
    },
    updateTable(records) {
      this.searchRecords = records
    },
    saveUpdatedRecord(updatedRecord) {
      updatedRecord = RIRecord.forSave(updatedRecord)
      this.setUpsertRecords(updatedRecord)

      const vaccinationNumber = updatedRecord.getVaccinationNumber()
      this.$buefy.dialog.alert({
        size: 'is-small',
        message: `Record saved offline. Vaccination Number: ${vaccinationNumber}`,
        onConfirm: () => { this.selectedRecord = undefined }
      })
    }
  }
}
</script>
