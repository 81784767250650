<template>
  <e-ri-form
    v-model="record"
    @go-back="$router.go(-1)"
    @submit="saveRecord()"
  />
</template>

<script>
import { mapMutations, mapState } from 'vuex'

import ERiForm from '../containers/ri-form'
import { RIRecord } from '../models'
import { generateVaccinationNumber } from '../utils/string'

export default {
  components: {
    ERiForm
  },
  data() {
    return {
      record: RIRecord.new()
    }
  },
  computed: { ...mapState(['programId']) },
  mounted() {
    this.record.profile.vaccinationNumber = generateVaccinationNumber()
  },
  methods: {
    ...mapMutations(['setUpsertRecords']),
    saveRecord() {
      const record = RIRecord.forSave(this.record)
      this.setUpsertRecords(record)
      this.$buefy.dialog.alert({
        size: 'is-small',
        message: `Record saved offline. Vaccination Number: ${record.getVaccinationNumber()}`,
        onConfirm: () => { this.$router.push({ name: 'recent-ri' }) }
      })
    }
  }
}
</script>
