import moment from 'moment'

/**
 * Represents an object that makes data from a similarly shaped list of object accessible as
 * fields on an object.
 *
 * Example:
 * The source data: [{attr: 'abc', value: 123}, {attr: 'def', value: 456}] is made accessible
 * as `{'abc': 123, 'def': 456, ...}` by DataObject.
 */
export class DataObject {
  constructor(args, keyAttribute, valueAttribute) {
    this.__keyAttribute = keyAttribute
    this.__valueAttribute = valueAttribute

    for (const field of Object.keys(args || {})) {
      this[field] = args[field]
    }
  }

  toEntityJSON() {
    return Object.keys(this)
      .filter((field) => !field.startsWith('__') && !!this[field])
      .map((field) => ({
        [this.__keyAttribute]: field,
        [this.__valueAttribute]: this[field]
      }))
      .filter((dict) => !!dict[this.__valueAttribute])
  }

  static fromEntityJSON(list, keyAttribute, valueAttribute) {
    const data = _flattenList(list || [], keyAttribute, valueAttribute)
    return new DataObject(data, keyAttribute, valueAttribute)
  }
}

export class Event {
  static Refs = ['enrollment', 'enrollmentStatus', 'orgUnit', 'program', 'trackedEntityInstance']
  static Fields = [
    'deleted',
    'dueDate',
    'event',
    'eventDate',
    'programStage',
    'status',
    'dataValues[dataElement,value]'
  ]

  constructor(args) {
    this.deleted = args.deleted
    this.dueDate = _parseItem(args.dueDate, 'datetime')
    this.eventDate = _parseItem(args.eventDate, 'datetime')
    this.event = args.event
    this.programStage = args.programStage
    this.status = args.status
    this.values = args.values // type: DataObject
  }

  toEntityJSON() {
    return {
      ..._toJSON(this, Event.Fields.slice(0, -1)),
      dataValues: this.values.toEntityJSON()
    }
  }

  static fromEntityJSON(data) {
    return new Event({
      ..._toJSON(data, Event.Fields.slice(0, -1)),
      values: DataObject.fromEntityJSON(data.dataValues, 'dataElement', 'value')
    })
  }
}

export class Enrollment {
  static Refs = ['orgUnit', 'program', 'trackedEntityInstance', 'trackedEntityType']
  static Fields = [
    'created',
    'createdAtClient',
    'lastUpdated',
    'lastUpdatedAtClient',
    'deleted',
    'enrollment',
    'enrollmentDate',
    'incidentDate',
    'program',
    'status',
    'trackedEntityType',
    `events[${Event.Fields.join(',')}]`
  ]

  constructor(args) {
    this.created = _parseItem(args.created, 'datetime')
    this.lastUpdated = _parseItem(args.lastUpdated, 'datetime')
    this.deleted = args.deleted
    this.enrollment = args.enrollment
    this.enrollmentDate = args.enrollmentDate
    this.incidentDate = _parseItem(args.incidentDate, 'datetime')
    this.status = args.status
    this.events = args.events // type: Array<Event>
  }

  toEntityJSON() {
    return {
      ..._toJSON(this, Enrollment.Fields.slice(0, -1)),
      events: this.events.map((e) => e.toEntityJSON())
    }
  }

  static fromEntityJSON(data) {
    return new Enrollment({
      ..._toJSON(data, Enrollment.Fields.slice(0, -1)),
      events: data.events.filter((e) => !e.deleted).map(Event.fromEntityJSON)
    })
  }

  static new() {
    return new Enrollment({
      created: new Date(),
      lastUpdated: new Date(),
      enrollmentDate: new Date(),
      incidentDate: new Date(),
      deleted: false,
      events: []
    })
  }
}

export class TrackedEntity {
  static Fields = [
    'created',
    'createdAtClient',
    'lastUpdated',
    'lastUpdatedAtClient',
    'orgUnit',
    'trackedEntityInstance',
    'trackedEntityType',
    'inactive',
    'deleted',
    'attributes[attribute,displayName,value]',
    `enrollments[${Enrollment.Fields.join(',')}]`
  ]

  constructor(args) {
    this.created = _parseItem(args.created, 'datetime')
    this.createdAtClient = _parseItem(args.createdAtClient, 'datetime')
    this.lastUpdatedAtClient = _parseItem(args.lastUpdatedAtClient, 'datetime')
    this.orgUnit = args.orgUnit
    this.trackedEntityInstance = args.trackedEntityInstance
    this.trackedEntityType = args.trackedEntityType
    this.inactive = args.inactive
    this.deleted = args.deleted

    // complex/object types
    this.attributes = args.attributes
    this.enrollment = args.enrollment
  }

  toEntityJSON(programId, orgUnit) {
    const entity = _toJSON(this, TrackedEntity.Fields.slice(0, -2))
    const metadata = {
      orgUnit: entity.orgUnit || orgUnit,
      program: programId,
      trackedEntityInstance: entity.trackedEntityInstance,
      trackedEntityType: entity.trackedEntityType
    }

    let enrollment = this.enrollment.toEntityJSON()
    enrollment = {
      ...enrollment,
      ...metadata,
      events: enrollment.events.map((event) => ({
        ...event,
        ...metadata,
        enrollment: enrollment.enrollment,
        enrollmentStatus: enrollment.status
      }))
    }

    return {
      ...entity,
      attributes: this.attributes.toEntityJSON(),
      enrollments: [enrollment]
    }
  }

  static fromEntityJSON(data, programID, entityType) {
    if (data.trackedEntityType !== entityType) {
      const expected = `Expected: ${entityType}`
      const provided = `Provided: ${data.trackedEntityType}`
      throw new Error(`Invalid trackedEntityType. ${expected}, ${provided}`)
    }

    const enrollment = data.enrollments
      .filter((e) =>
        !e.deleted &&
        e.program === programID &&
        e.trackedEntityType === entityType
      )
      .sort((x, y) => x.created > y.created ? -1 : 1) // descending order
      .slice(-1)[0] || { events: [] }

    return new TrackedEntity({
      ..._toJSON(data, TrackedEntity.Fields.slice(0, -2)),
      attributes: DataObject.fromEntityJSON(data.attributes, 'attribute', 'value'),
      enrollment: Enrollment.fromEntityJSON(enrollment || { events: [] })
    })
  }

  static new(orgUnit, trackedEntityType) {
    return new TrackedEntity({
      orgUnit,
      trackedEntityType,
      created: new Date(),
      createdAtClient: new Date(),
      lastUpdatedAtClient: new Date(),
      deleted: false,
      inactive: false,
      attributes: new DataObject({}, 'attribute', 'value'),
      enrollment: Enrollment.new()
    })
  }
}

/**
 * Builds an object with a subset of the specified fields from the original object
 * @param {Object} object target object to extract data from to build JSON
 * @param {*} fields list of object attributes to extract to build JSON
 * @returns
 */
export function _toJSON(object, fields) {
  return fields.reduce(
    (data, field) => ({
      ...data,
      [field]: object[field]
    }),
    {}
  )
}

/**
 * Builds a object from key-value data pulled from a list of (similarly shaped) objects.
 * @param {Array<Object>} objects list of (similarly shaped) objects to flatten
 * @param {string} keyField the attribute in objects in the list whose value is to become
 *                          a field in the new object
 * @param {string} valueField the attribute in objects in the list whose value is to become
 *                          a value for the field set by keyField
 * @returns the built (flattened) object
 */
export function _flattenList(objects, keyField, valueField) {
  return (objects || [])
    .filter((item) => (keyField in item) && (valueField in item))
    .map((item) => ({ item, id: item[keyField], value: item[valueField] }))
    .reduce(
      (data, info) => ({
        ...data,
        [info.id]: _parseItem(info.value, 'string')
      }),
      {}
    )
}

/**
 * Builds a object from key-value data pulled from a list of (similarly shaped) objects.
 * @param {Array<Object>} objects list of (similarly shaped) objects to flatten
 * @param {string} keyField the attribute in objects in the list whose value is to become
 *                          a field in the new object
 * @param {string} valueField the attribute in objects in the list whose value is to become
 *                          a value for the field set by keyField
 * @param {Object} mapping a mapping of keyField values to a unique human readable label
 * @returns the built (flattened) object
 */
export function _flattenListWithMapping(objects, keyField, valueField, mapping) {
  return (objects || [])
    .filter((item) => (keyField in item) && (valueField in item) && (item[keyField] in mapping.ID))
    .map((item) => ({ item, id: item[keyField], field: mapping.ID[item[keyField]] }))
    .reduce(
      (data, info) => ({
        ...data,
        [info.field]: _parseItem(info.item[valueField], mapping.Field[info.field].type)
      }),
      {}
    )
}

export function _parseItem(value, type) {
  switch (type) {
    case 'number':
      return parseFloat(value)

    case 'date':
      const date = moment(value, ['YYYY-MM-DD', moment.ISO_8601])
      if (date.isValid()) {
        return date.toDate()
      }
      return null

    case 'datetime':
      const dateTime = moment(value, moment.ISO_8601)
      if (dateTime.isValid()) {
      /* Hack:
        Reset datetime 00:00:00 hour to 12:00:00 to avoid timezone overflow to the next day
        The assumption is a date with time 00:00:00 in unlikely to be a timestamp
      */
        if (dateTime.hour() === 0 && dateTime.minute() === 0 && dateTime.second() === 0) {
          dateTime.hours(12)
        }
        return dateTime.toDate()
      }
      return null

    case 'boolean':
      return ['true', true].includes(value)

    default:
      return value
  }
}

export function _fromDataObject(obj, mapping) {
  return Object.keys(obj)
    // exclude __keyAttribute and __valueAttribute
    .filter((id) => !id.startsWith('__') && !!obj[id])
    .reduce((args, id) => ({
      ...args,
      [mapping.ID[id]]: obj[id]
    }), {})
}

export function _toDataObject(data, mapping) {
  return Object.keys(data)
    .filter((field) => !!data[field])
    .reduce((dict, field) => ({
      ...dict,
      [mapping.Field[field].id]: data[field]
    }), {})
}
