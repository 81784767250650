<template>
  <b-navbar>
    <template #brand>
      <b-navbar-item @click="goto('home')">
        <img
          alt="Logo"
          width="30"
          height="50"
          src="../assets/logo.png"
        > &nbsp;
        <strong class="has-text-justified truncate">
          <small>{{ getOrganizationName }}</small>
        </strong>
        <b-tag
          v-show="hasUserTeam"
          class="ml-3"
          type="is-primary"
          rounded
        >
          {{ getUserTeamName }}
        </b-tag>
      </b-navbar-item>
      <b-navbar-item>
        <e-select
          size="is-small"
          custom-class="m-0"
          :options="programChoices()"
          :value="programId"
          @input="changeProgram($event)"
        />
      </b-navbar-item>
    </template>

    <template #start>
      <b-navbar-item
        v-for="(label, key) of mainMenu()"
        v-show="showMenuItem(key)"
        :key="key"
        @click="goto(key)"
      >
        {{ label }}
      </b-navbar-item>
    </template>

    <template #end>
      <b-navbar-item tag="div">
        <div class="buttons">
          <a
            class="button is-rounded is-small is-success"
            @click="() => syncRecords(false)"
          >
            <strong>Sync Records</strong>
          </a>
          <a
            class="button is-rounded is-small"
            @click="logout"
          >
            Log Out
          </a>
        </div>
      </b-navbar-item>
    </template>
  </b-navbar>
</template>

<script>
import isEmpty from 'lodash/isEmpty'
import { mapGetters, mapMutations, mapState } from 'vuex'

import { Duration, NavMenu } from '../constants'
import { ProgramID } from '../constants/ids'
import { handleSyncEvent } from '../services/dhis'
import { programChoices } from '../utils/choices'
import { ESelect } from './generic'

export default {
  name: 'ENavbar',
  components: {
    ESelect
  },
  data() {
    return {
    }
  },
  computed: {
    ...mapGetters(['getOrganizationName', 'getUserTeamName', 'hasUserTeam']),
    ...mapState(['auth', 'programId', 'upsertRecords'])
  },
  mounted() {
    // automatically perform sync at regular intervals
    this.$nextTick(function() {
      window.setInterval(() => this.syncRecords(true), 3 * Duration.Minute)
    })
  },
  methods: {
    ...mapMutations(['resetUpsertRecords', 'setAuth', 'setProgram']),
    programChoices,

    mainMenu() { return NavMenu },
    changeProgram(program) {
      this.setProgram(program)
      if (this.$route.name !== 'home') {
        this.$router.push({ name: 'home' })
      }
    },
    goto(routeName) {
      if (this.programId === ProgramID.RI && !['home', 'pending'].includes(routeName)) {
        routeName = `${routeName}-ri`
      }

      if (this.$route.name !== routeName) {
        this.$router.push({ name: routeName })
      }
    },
    logout() {
      if (!isEmpty(this.upsertRecords)) {
        this.$buefy.toast.open('Sync offline records before logging out!')
        return false
      }

      this.setAuth({})
      this.resetUpsertRecords()
      localStorage.removeItem('vuex')
      this.$router.push({ name: 'login' })
    },
    showMenuItem(menuItem) {
      if (this.programId !== ProgramID.COVID) {
        return ['create', 'pending', 'recent'].includes(menuItem)
      }
      return true
    },
    async syncRecords(quiet = false) {
      const context = {
        quiet,
        auth: this.auth,
        online: navigator.onLine,
        programId: this.programId,
        routeName: this.$route.name
      }

      handleSyncEvent(context, Object.values(this.upsertRecords))
    }
  }
}
</script>

<style scoped>
  nav {
    font-size: 14px !important;
    margin-bottom: 30px;
  }
  .menu.is-active button{
    background: green;
    color: white;
  }

  @media(max-width: 400px) {
    .truncate {
      width: 170px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }
</style>
