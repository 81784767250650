const Millisecond = 1
const Second = 1000 * Millisecond
const Minute = 60 * Second
const Hour = 60 * Minute
const Day = 24 * Hour

export const Duration = { Day, Hour, Millisecond, Minute, Second }

export const NavMenu = {
  create: 'Create Record',
  recent: 'List Records',
  pending: 'Offline Records',
  verify: 'Verify Records'
}
