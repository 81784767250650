export default {
  data() {
    return {
      qrcode: undefined
    }
  },
  methods: {
    async onQrInit (promise) {
      try {
        await promise
      } catch (error) {
        let message = ''

        switch (error.name) {
          case 'NotAllowedError': // user denied camera access permission
            message = 'Kindly re-install the App and Enable Camera Permission.'
            break
          case 'NotFoundError':
            message = 'Camera is not configured properly. Kindly re-install App.'
            break
          case 'NotSupportedError': // page is not served over HTTPS (or localhost)
            message = 'Request new link and kindly re-install App'
            break
          case 'NotReadableError': // maybe camera is already in use
            message = 'Camera in use'
            break
          case 'StreamApiNotSupportedError': // browser seems to be lacking features
            message = 'Scanning is not supported for your device. Please request new link.'
            break
        }

        this.$buefy.toast.open(message)
      } finally {
        // hide loading indicator
      }
    },
    onQrDecode(decodedString) {
      this.qrcode = decodedString
      if (this.qrcode?.includes('verify')) {
        this.$buefy.dialog.alert('QR code scan is successful!')
      }
    }
  }
}
