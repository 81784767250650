<template>
  <div class="columns is-desktop is-centered mt-6">
    <div class="column is-half-desktop has-text-centered mt-6">
      <img
        alt="Logo"
        height="183px"
        width="200px"
        class="mb-5"
        src="../assets/logo.png"
      >

      <b-field>
        <b-input
          v-model="username"
          expanded
          rounded
          type="text"
          placeholder="Username"
        />
      </b-field>

      <b-field>
        <b-input
          v-model="password"
          expanded
          icon-clickable
          password-reveal
          rounded
          type="password"
          placeholder="Password"
        />
      </b-field>

      <b-button
        expanded
        rounded
        type="is-success"
        :disabled="disabled"
        @click="login"
      >
        Login
      </b-button>
    </div>
  </div>
</template>

<script>
import { mapMutations, mapState } from 'vuex'

import { ProgramID } from '../constants/ids'
import { User } from '../models'
import { fetchCovidTrackedEntity, fetchRITrackedEntity } from '../services/dhis'
import { getBasicAuthHeaders, getBasicAuthToken, isCredentialEmptyOrNull } from '../utils/auth'
import { fetchData } from '../utils/fetch'

export default {
  name: 'Login',
  data() {
    return {
      disabled: false,
      isLoading: false,
      username: '',
      password: ''
    }
  },
  computed: mapState(['auth', 'pageSize', 'programId']),
  methods: {
    ...mapMutations(['setAuth', 'setData']),
    async login() {
      if (isCredentialEmptyOrNull(this.username, this.password)) {
        this.$buefy.toast.open('Kindly input username and password')
        return false
      }

      if (!navigator.onLine) {
        this.$buefy.toast.open('You are offline. Login requires Internet connection!')
        return false
      }

      const token = getBasicAuthToken(this.username, this.password)
      const headers = getBasicAuthHeaders(token)
      const config = {
        headers,
        params: { fields: User.Fields.join(',') }
      }

      try {
        // perform login
        const data = (await fetchData('/me', config)).data
        const user = User.fromJSON(data)

        if (!user.organisation) {
          this.$buefy.toast.open('Invalid username and/or password!')
          return false
        }

        // set auth data
        const auth = {
          token,
          user,
          ou: user.organisation.id
        }

        this.$buefy.toast.open('Login Successful')
        this.isLoading = true
        this.setAuth(auth)
      } catch (err) {
        console.error(`Authentication failed. Error: ${err}`)
        this.$buefy.toast.open('Invalid username and/or password!')
        return false
      }

      // This is to allow the message above to flash first, the time between messages is very small
      setTimeout(() => this.$buefy.toast.open('Fetching recent records...'), 1000)

      this.disabled = true
      this.$router.push({ name: 'home' })

      const context = {
        auth: this.auth,
        headers: config.headers,
        pageSize: this.pageSize
      }

      try {
        Promise
          .all([
            fetchCovidTrackedEntity({ ...context, programId: ProgramID.COVID }),
            fetchRITrackedEntity({ ...context, programId: ProgramID.RI })
          ])
          .finally(() => { this.isLoading = false })
      } catch (err) {
        console.log(`Recent records fetch operation failed. Error: ${err}`)
      }
    }
  }
}
</script>

<style scoped>
  .field {
    margin-bottom: 1rem;
  }

  .is-half {
    margin-top: 2rem;
  }
</style>
