/**
 * COVID Vaccine Types
 */
export const VaccineType = {
  AstraZeneca: 'as',
  Johnson: 'johnson',
  Pfizer: 'biontechpfizer',
  Moderna: 'moderna'
}

/**
 * Mapping of vaccine types to a corresponding display label/name.
 */
export const VaccineDisplayNameMap = {
  [VaccineType.AstraZeneca]: 'AstraZeneca',
  [VaccineType.Moderna]: 'Moderna',
  [VaccineType.Johnson]: 'Johnson and Johnson',
  [VaccineType.Pfizer]: 'Pfizer-Bio-N-Tech'
}

export const VaccineIDSet = {
  All: Object.values(VaccineType)
}

/**
 * Vaccine batch ids by vaccine type.
 */
export const VaccineBatchIDSet = {
  [VaccineType.AstraZeneca]: [
    '4121Z005', '4121Z006',
    '4121Z015',
    '084D21A', '084D21A-1',
    '939895', '939896',
    '059E21A', 'PV46706',
    '4120Z030'
  ],
  [VaccineType.Moderna]: ['084D21A', '939895']
}
