import { DHIS_API_URL } from '../constants/env'
import httpClient from './httpClient'

function getUrl(url) {
  if (!url.startsWith('http') && url.startsWith('/')) {
    url = `${DHIS_API_URL}${url}`
  }
  return url
}

export function fetchData(url, config) {
  return httpClient.get(getUrl(url), config)
}

export function fetchTrackedEntity(config) {
  return fetchData('/trackedEntityInstances.json', config)
}

export function postData(url, data, config) {
  return httpClient.post(getUrl(url), data, config)
}

export function putData(url, data, config) {
  return httpClient.put(getUrl(url), data, config)
}

export function postTrackedEntity(data, config) {
  return postData('/trackedEntityInstances', data, config)
}

export function putTrackedEntity(data, config) {
  return putData(`/trackedEntityInstances/${data.trackedEntityInstance}`, data, config)
}

export function postEnrollment(data, config) {
  return postData('/enrollments', data, config)
}

export function putEnrollment(data, config) {
  return putData(`/enrollments/${data.enrollment}`, data, config)
}

export function postEnrollmentEvent(data, config) {
  return postData('/events', data, config)
}

export function putEnrollmentEvent(data, config) {
  return putData(`/events/${data.event}`, data, config)
}
