/**
 * Represents the DHIS2 program details for COVID Vaccination Registry.
 */
export const ProgramID = {
  COVID: 'gWuxRU2yJ1x',
  RI: 'SSLpOM0r1U7'
}

/**
 * DHIS2 Attribute identification values
 */
export const AttributeID = {
  COVID: {
    FamilyName: 'aW66s2QSosT',
    OtherName: 'TfdH5KvFmMy',
    BirthDate: 'mAWcalQYYyk',
    Gender: 'CklPZdOd6H1',
    Email: 'N18AMajv6sh',
    PhoneNumber: 'Xhzv5vjYeOW',
    ResidentialAddress: 'o1BJQ0skXL5',
    VaccinationNumber: 'izttywqePh2',
    Occupation: 'DTESTXiQhyT',
    PregnancyStatus: 'QQ5ZNIyAQoj',
    HasAllergies: 'Sjd1tA8vOQc',
    IsAgeEstimated: 'Z1rLc1rVHK8',
    IsFrontlineHealthWorker: 'wO5SdDBuM3l',
    IsHealthWorker: 'ZhoouEAMPzz',
    IDType: 'OvGXY097Hxt',
    IDNumber: 'fXHMMrKgEWk'
  },
  // Routine Immunization (RI)
  RI: {
    VaccinationNumber: 'izttywqePh2',
    BirthDate: 'NI0QRzJvQ0k',
    FirstName: 'sB1IHYu2xQT',
    Surname: 'ENRjVGxVL6l',
    Gender: 'oindugucx72',
    HasRegisteredChild: '',

    CareGiverFirstName: 'pTgaQiv4xRo',
    CareGiverSurname: 'ezslHxV1ybB',
    CareGiverPhoneNumber: 'KnQryoomh60',
    Address: 'Xhdn49gUd52',
    Settlement: 'xsxMR7crjZ6'
  }
}

/**
 * DHIS2 entity names that also found within API endpoint paths
 */
export const EntityName = {
  TrackedEntityInstance: 'trackedEntityInstances'
}

/**
 * DHIS2 tracked entity type identification values
 */
export const TrackedEntityTypeID = {
  COVID: 'ag6Yk7fwUEe', // COVID Records
  RI: 'MCPQUTHX1Ze' // RI Records
}

/**
 * DHIS2 data element identification values
 */
export const DataElementID = {
  COVID: {
    QRCode: 'LavUrktwH5D',
    Verified: 'nArIDvQ9air',
    OutsideNigeria: 'h0E1oYhCcbP',
    UploadPhoto: 'NFbuqYkYJi9',
    VaccineType: 'c7FAZD2q7nJ',
    Vaccinated: 'wOyOg4cO6uO',
    Vaccinated2ndDose: 'Q7a5moeAYpQ',
    VaccineBatchNumber: 'CfIjmVobuul',
    NextDoseSuggestedDate: 'vqg9zD9AOhR',
    PreSelectedBySystem: 'v4VKjz26iv2',
    DoseNumber: 'LUIsbsm3okG'
  },
  RI: {
    // Event 1
    BirthAttendant: 'lQtJB35vsDj',
    BirthFacility: 'RTA1VXtS6r1',
    BirthSettlement: 'cCQRaVSSOsY',
    BirthType: 'LtlzGAPWWo8',
    BirthWeight: 'WUquHs0Al7h',
    DeliveryMode: 'fF7wxNym0Un',
    DeliveryPlace: 'ABhkInP0wGY',
    GestationalAge: 'SaCLndgg6On',
    Parity: 'B5BoWbyB1sY',

    // Event 2
    BirthRegistrationDate: 'vbqDtmenuL0',
    HasCollectedBirthCertificate: 'bJykvtIiw4q',
    HasImmunodeficiencyOrHIV: 'ptgXqqoq8pf',
    HasHighTemperatureOrVerySick: 'zpyTHpOZVnc',
    HasVaccineAllergy: 'UnXfP6q3uOp',
    VaccinationLocation: 'HdzfEcDLnhX',
    UpdateHIVStatus: 'LoaA9ETgadk',

    AntigenBCG: 'bpBUOvqy1Jn',
    AntigenBOPV0: 'no7SkAxepi7',
    AntigenHEPB0: 'qEtgKXZlMLE',

    AntigenPENTA1: 'K3TcJM1ySQA',
    AntigenPCV1: 'fmXCCPENnwR',
    AntigenROTA1: 'Y3eDHMTcSYk',
    AntigenIPV1: 'Fu83xQ4NKuQ',

    AntigenPENTA2: 'GPOoPDqle3V',
    AntigenPCV2: 'PvHUllrtPiy',
    AntigenROTA2: 'knh2BOb3F0I',

    AntigenPENTA3: 'pxCZNoqDVJC',
    AntigenPCV3: 'B4eJCy6LFLZ',
    AntigenROTA3: 'xMkkGCwSaDK',
    AntigenIPV2: 'NxVIX3mIhGt',

    AntigenBOPV1: 'CfPM8lsEMzH',
    AntigenBOPV2: 'sDORmAKh32v',
    AntigenBOPV3: 'nQeUfqPjK5o',

    AntigenVitaminA1: 'vitaminA1x',

    AntigenMeasles1: 'g8dMiUOTFla',
    AntigenYellowFever: 'mH9watsYzvq',
    AntigenMeningitis: 'meningitisx',

    AntigenVitaminA2: 'vitaminA2x',
    AntigenMeasles2: 'Bxh1xgIY9nA',

    AntigenHPV1: 'ZYsq1NLl1Vu'
  }
}

export const ProgramStagesID = {
  /**
   * Program stages for COVID program
   * GET /api/programs/{id}.json?fields=programStages[id,name,displayName]
   */
  [ProgramID.COVID]: {
    Verification: 'Fwc5G0VTdlV',
    Vaccination: 'KPc2vZ0IyiH',
    AEFIReporting: 'JQ99FbgrCIY'
  },

  /**
   * Program stages for RI program
   * GET /api/programs/{id}.json?fields=programStages[id,name,displayName]
   */
  [ProgramID.RI]: {
    BirthDetails: 'RcbCl5ww8XY',
    Immunization: 's53RFfXA75f'
  }
}

export const TeamCategoryID = {
  Team1: 'QD0IJhySSY1',
  Team2: 'Q6helyf5FCS',
  Team3: 'Nt1plbbiP2E',
  Team4: 'uzNGRq2WKgM',
  Team5: 'Wufljd2iYEa',
  Fixed: 'uZ0jiG3cw2G',
  NPSIA: 'uzI7uZ4B5tX'
}

/**
 * DHIS2 ids for User Groups for teams
 */
export const TeamUserGroupID = {
  [TeamCategoryID.Team1]: 'L0cEZHlLBih',
  [TeamCategoryID.Team2]: 'wFTA6OGfdUU',
  [TeamCategoryID.Team3]: 'LHNE96nl4Jb',
  [TeamCategoryID.Team4]: 'IUx221e8uta',
  [TeamCategoryID.Team5]: 'AVNs38rPBPT',
  [TeamCategoryID.Fixed]: 'BwxMyH9Ml4s',
  [TeamCategoryID.NPSIA]: ''
}

export const TeamCategoryOptions = [
  { value: TeamCategoryID.Team1, label: 'Team 1' },
  { value: TeamCategoryID.Team2, label: 'Team 2' },
  { value: TeamCategoryID.Team3, label: 'Team 3' },
  { value: TeamCategoryID.Team4, label: 'Team 4' },
  { value: TeamCategoryID.Team5, label: 'Team 5' },
  { value: TeamCategoryID.Fixed, label: 'Fixed Team' },
  { value: TeamCategoryID.NPSIA, label: 'NPSIA' }
]
