<template>
  <b-tab-item
    label="Immunizations"
  >
    <e-collapse-card
      title="Pre-Immunization Details"
    >
      <e-select
        custom-class="mb-3"
        label-position="above"
        label="Where did the child receive these immunizations?"
        placeholder="Select immunization location"
        class="mt-3"
        :error="errors.vaccinationLocation"
        :options="riVaccinationLocationChoices()"
        :value="preImmunizationDetails.vaccinationLocation"
        @input="updatePreImmunizationDetails('vaccinationLocation', $event)"
      />

      <e-select
        custom-class="mb-2"
        label-position="above"
        label="Has child had any severe, life-threatening allergies to vaccines or anything else?"
        placeholder="Select Response"
        :error="errors.hasVaccineAllergy"
        :options="hasVaccineAllergyChoices()"
        :value="preImmunizationDetails.hasVaccineAllergy"
        @input="updatePreImmunizationDetails('hasVaccineAllergy', $event)"
      />

      <e-select
        custom-class="mb-2"
        label-position="above"
        :label="`Is the child currently very sick and/or have a very high temperature
          (>39 degrees Celsius)?`"
        placeholder="Yes or No?"
        :error="errors.hasHighTemperatureOrVerySick"
        :options="yesNoUnknownChoices()"
        :value="preImmunizationDetails.hasHighTemperatureOrVerySick"
        @input="updatePreImmunizationDetails('hasHighTemperatureOrVerySick', $event)"
      />
    </e-collapse-card>

    <e-collapse-card
      v-for="(period) in vaccinationPeriods"
      :key="period"
      :title="cardTitle(period)"
    >
      <e-checkbox-list
        :value="getVaccinationsByPeriod[period]"
        @input="updateAntigens(period, $event)"
      />
    </e-collapse-card>
  </b-tab-item>
</template>

<script>
import ECheckboxList from '../components/checkbox-list'
import ECollapseCard from '../components/collapse-card'
import { ESelect } from '../components/generic'
import { RIVaccinationPeriodDisplayNameMap } from '../constants/options'
import { Record as RIRecord, VaccinationEntry } from '../models/dhis.record-ri'
import {
  hasVaccineAllergyChoices,
  riVaccinationLocationChoices,
  riVaccinationPeriodsByDuration,
  yesNoUnknownChoices
} from '../utils/choices'

export default {
  name: 'ERIVaccinationForm',
  components: {
    ECollapseCard,
    ECheckboxList,
    ESelect
  },
  props: {
    ageDurations: { type: Object, default: undefined },
    value: { type: Array, default() { return [] } }
  },
  data() {
    return {
      errors: {},
      indicators: {},
      newValue: this.value
    }
  },
  computed: {
    computedValue: {
      get() {
        return this.newValue ? this.newValue : []
      },
      set(value) {
        this.newValue = value
        this.$emit('input', value)
      }
    },
    getVaccinationsByPeriod() {
      return RIRecord.getVaccinationsByPeriod(this.computedValue)
    },
    preImmunizationDetails() {
      return this.computedValue?.length >= 1 ? this.computedValue[0].values : {}
    },
    vaccinationPeriods() {
      return riVaccinationPeriodsByDuration(this.ageDurations || { weeks: 0, months: 0 })
    }
  },
  watch: {
    value(value) {
      this.newValue = value
    }
  },
  methods: {
    hasVaccineAllergyChoices,
    riVaccinationLocationChoices,
    yesNoUnknownChoices,

    cardTitle(period) {
      return RIVaccinationPeriodDisplayNameMap[period]
    },
    updatePreImmunizationDetails(key, value) {
      const values = { ...this.computedValue[0].values }
      values[key] = value

      const computedValue = [...this.computedValue]
      computedValue[0] = {
        ...computedValue[0],
        vaccinationDate: new Date(),
        modified: true,
        values
      }

      this.computedValue = computedValue
    },
    updateAntigens(period, updatedAntigens) {
      // pick antigens from the last item in the array
      const antigens = { ...this.computedValue.slice(-1)[0].values.antigens }
      const data = updatedAntigens
        .filter((antigen) => antigen._updated)
        .reduce((dict, antigen) => ({
          ...dict,
          [`antigen${antigen.name}`]: new VaccinationEntry(antigen)
        }), {})

      this.updateValues('antigens', { ...antigens, ...data })
    },
    updateValues(key, value) {
      // update values for the last item in the array
      const index = this.computedValue.length - 1
      const values = { ...this.computedValue[index].values }
      values[key] = value

      const computedValue = [...this.computedValue]
      computedValue[index] = {
        ...computedValue[index],
        vaccinationDate: new Date(),
        modified: true,
        values
      }

      this.computedValue = computedValue
    }
  }
}
</script>
