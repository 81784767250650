<template>
  <div :class="{ cols: useColumns }">
    <e-checkbox
      v-for="(item, index) in computedValue"
      :key="item.key"
      :disabled="item.disabled || disabledAntigens.includes(item.name)"
      :label="item.label"
      :value="item.taken && !disabledAntigens.includes(item.name)"
      class="item"
      @input="updateValue(item.key, index, $event)"
    />
  </div>
</template>

<script>
import ECheckbox from './generic/checkbox'

export default {
  name: 'ECheckboxList',
  components: {
    ECheckbox
  },
  inject: {
    disabledAntigens: {
      default: []
    }
  },
  props: {
    useColumns: { type: Boolean, default: false },
    value: { type: Array, default() { return [] } }
  },
  data() {
    return {
      newValue: this.value
    }
  },
  computed: {
    computedValue: {
      get() {
        return this.newValue ? this.newValue : []
      },
      set(value) {
        this.newValue = value
        this.$emit('input', value)
      }
    }
  },
  watch: {
    value(value) {
      this.newValue = value
    }
  },
  methods: {
    updateValue(key, index, value) {
      const data = [...(this.computedValue || [])]
      data[index] = {
        ...(this.computedValue[index] || {}),
        date: value ? new Date() : undefined,
        taken: value,
        _updated: true // metadata
      }

      this.computedValue = data
    }
  }
}
</script>

<style scoped>
  .cols {
    display: flex;
    flex-flow: row wrap;
    justify-content: flex-start;
  }

  .cols .item {
    flex-grow: 3;
    flex-shrink: 3;
    flex-basis: 1;
    width: 100%;
  }

  @media(min-width: 610px) {
    .cols .item { width: 30%; }
  }

  @media(max-width: 610px) {
    .cols .item { width: 50%; }
  }

  @media(max-width: 500px) {
    .cols .item { width: 100%; }
  }
</style>
