export default {
  AB: 'Abia',
  AD: 'Adamawa',
  AK: 'Akwa Ibom',
  AN: 'Anambra',
  BA: 'Bauchi',
  BY: 'Bayelsa',
  BE: 'Benue',
  BO: 'Borno',
  CR: 'Cross River',
  DE: 'Delta',
  EB: 'Ebonyi',
  ED: 'Edo',
  EK: 'Ekiti',
  EN: 'Enugu',
  FC: 'FCT Abuja',
  GO: 'Gombe',
  IM: 'Imo',
  JI: 'Jigawa',
  KD: 'Kaduna',
  KN: 'Kano',
  KT: 'Katsina',
  KE: 'Kebbi',
  KO: 'Kogi',
  KW: 'Kwara',
  LA: 'Lagos',
  NA: 'Nasarawa',
  NI: 'Niger',
  OG: 'Ogun',
  ON: 'Ondo',
  OS: 'Osun',
  OY: 'Oyo',
  PL: 'Plateau',
  RI: 'Rivers',
  SO: 'Sokoto',
  TA: 'Taraba',
  YO: 'Yobe',
  ZA: 'Zamfara'
}
