export class OrganisationUnit {
  static Fields = ['id', 'displayName']

  constructor(args) {
    this.id = args.id
    this.displayName = args.displayName
  }

  static fromJSON(data) {
    const args = OrganisationUnit.Fields.reduce(
      (org, field) => ({
        ...org,
        [field]: data[field]
      }),
      {}
    )

    return new OrganisationUnit(args)
  }
}
