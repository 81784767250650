import moment from 'moment'

import { AttributeID } from '../constants/ids'

export function ageDurations(birthDate) {
  const parts = { days: 0, weeks: 0, months: 0, years: 0 }
  if (birthDate) {
    const duration = moment.duration(moment() - moment(birthDate))
    parts.years = Math.floor(duration.asYears())
    parts.months = Math.floor(duration.asMonths())
    parts.weeks = Math.floor(duration.asWeeks())
    parts.days = Math.floor(duration.asDays())
  }
  return parts
}

export function isAgeAbove(birthDate, age) {
  const today = moment()
  const projectedDate = moment(birthDate).add(age, 'years')

  if (projectedDate.isBefore(today)) {
    const diff = moment.duration(today - projectedDate)
    return Math.floor(diff.asDays()) > 0
  }

  return false
}

export function isAgeBelow(birthDate, age) {
  const today = moment()
  const projectedDate = moment(birthDate).add(age, 'years')

  if (projectedDate.isAfter(today)) {
    const diff = moment.duration(projectedDate - today)
    return Math.floor(diff.asDays()) > 0
  }

  return false
}

const datetimeFields = [
  'created',
  'createdAtClient',
  'dueDate',
  'enrollmentDate',
  'eventDate',
  'lastUpdated',
  'lastUpdatedAtClient',
  'incidentDate'
]
const getDateString = (str) => {
  if (str) {
    const regex = /^\d{4}-\d{2}-\d{2}/
    if (typeof str === 'string' && str.match(regex)) {
      return str.substring(0, 10)
    }
    if (typeof str === 'object') {
      return getDateString(str.toISOString())
    }
  }
  return str
}

export function updateEventDates (events) {
  events.forEach(event => {
    Object.keys(event).forEach(key => {
      if (datetimeFields.includes(key)) {
        event[key] = getDateString(event[key])
      }
    })
  })
}

function updateEnrollmentDates (enrollments) {
  enrollments.forEach((enrollment) => {
    Object.keys(enrollment).forEach(key => {
      if (datetimeFields.includes(key)) {
        enrollment[key] = getDateString(enrollment[key])
      }
      if (key === 'events') updateEventDates(enrollment[key])
    })
  })
}

export function updateTrackedEntityDates(trackedInstance) {
  Object.keys(trackedInstance).forEach(key => {
    if (key === 'profile') {
      trackedInstance[key].birthDate = getDateString(trackedInstance[key].birthDate)
    }
    if (datetimeFields.includes(key)) {
      trackedInstance[key] = getDateString(trackedInstance[key])
    }
    if (key === 'enrollments') {
      updateEnrollmentDates(trackedInstance[key])
    }
    if (key === 'attributes') {
      trackedInstance[key].find(dt => {
        const isBirthDateField = dt.attribute === AttributeID.COVID.BirthDate
        if (isBirthDateField) {
          dt.value = getDateString(dt.value)
        }
        return isBirthDateField
      })
    }
  })
}
