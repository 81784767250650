import { ToastProgrammatic as Toast } from 'buefy'
import groupBy from 'lodash/groupBy'
import isEmpty from 'lodash/isEmpty'
import merge from 'lodash/merge'

import { DataElementID, ProgramID, TeamUserGroupID, TrackedEntityTypeID } from '../constants/ids'
import { CovidRecord, isCovidRecord, RIRecord } from '../models'
import { TrackedEntity } from '../models/dhis.tracked-entity'
import store from '../store'
import { getBasicAuthHeaders } from '../utils/auth'
import {
  fetchTrackedEntity,
  postEnrollmentEvent,
  postTrackedEntity,
  putEnrollmentEvent,
  putTrackedEntity
} from '../utils/fetch'

const RecordType = { COVID: 'covid', RI: 'ri' }

export function fetchCovidTrackedEntity(context) {
  const config = {
    headers: context.headers,
    params: {
      fields: TrackedEntity.Fields.join(','),
      filter: context.filter,
      ou: context.auth.ou,
      ouMode: context.ouMode || 'SELECTED',
      order: 'created:desc',
      pageSize: context.pageSize,
      program: ProgramID.COVID,
      totalPages: false
    }
  }

  return fetchTrackedEntity(config)
    .then((data) => data.data.trackedEntityInstances)
    .then((rows) => (rows || []).map((r, index) => {
      const ent = TrackedEntity.fromEntityJSON(r, context.programId, TrackedEntityTypeID.COVID)
      ent.__index = index
      return ent
    }))
    .then((records) => { store.commit('setCOVIDRecords', records) })
    .catch((err) => {
      console.warn(`COVID records fetch failed: ${err}`)
      Toast.open('Failed to fetch recent COVID records. Try again later.')
    })
}

export function fetchRITrackedEntity(context) {
  const config = {
    headers: context.headers,
    params: {
      fields: TrackedEntity.Fields.join(','),
      filter: context.filter || '',
      ou: context.auth.ou,
      ouMode: context.ouMode || 'ACCESSIBLE',
      order: 'created:desc',
      pageSize: context.pageSize,
      program: ProgramID.RI,
      totalPages: false
    }
  }

  return fetchTrackedEntity(config)
    .then((data) => data.data.trackedEntityInstances)
    .then((rows) => (rows || []).map((r, index) => {
      const ent = TrackedEntity.fromEntityJSON(r, context.programId, TrackedEntityTypeID.RI)
      ent.__index = index
      return ent
    }))
    .then((records) => { store.commit('setRIRecords', records) })
    .catch((err) => {
      console.warn(`RI records fetch failed: ${err}`)
      Toast.open('Failed to fetch recent RI records. Try again later.')
    })
}

export async function handleSyncEvent(context, records = undefined) {
  console.log(`syncing now @ ${new Date().getTime()} ...`)
  if (!context.online) {
    if (!context.quiet) Toast.open('You are offline. Sync required Internet connection!')
    return
  }

  if (isEmpty(records)) {
    if (context.routeName === 'pending' && !context.quite) {
      Toast.open('All records are now uploaded 👍')
    }
    return
  }

  records = groupBy(records, (r) => isCovidRecord(r) ? RecordType.COVID : RecordType.RI)
  let result = { created: [], updated: [], failed: [] }

  for (const [items, type] of [
    [records.covid, RecordType.COVID],
    [records.ri, RecordType.RI]
  ]) {
    if (!items) continue

    context.recordType = type
    const out = await syncRecords(context, items)
    result = merge(result, out)
  }

  const message = `Sync Completed. Created: ${result.created.length}. ` +
                  `Updated: ${result.updated.length}. ` +
                  `Failed: ${result.failed.length}`

  // update offline records
  const vaccinationNumbers = [...result.created, ...result.updated]
  if (vaccinationNumbers.length) {
    store.commit('removeUpsertRecords', { vaccinationNumbers, synced: true })
  }

  console.log(message)
  if (!context.quiet && context.routeName === 'pending') {
    Toast.open(message)
  }
}

export async function syncRecords(context, records = undefined) {
  let summary = { created: [], updated: [], failed: [] }
  records = (records || []).map(
    (r) => context.recordType === RecordType.COVID ? new CovidRecord(r) : new RIRecord(r)
  )

  const data = groupBy(records, (r) => !r.entity.trackedEntityInstance ? 'new' : 'old')

  // sync new records
  if (data.new) {
    try {
      const result = await createRecords(context, data.new)
      summary = merge(summary, result)
    } catch (err) {
      console.error(`Creating records failed. Error: ${err}`)
    }
  }

  // sync old records
  if (data.old) {
    try {
      const result = await updateRecords(context, data.old || [])
      summary = merge(summary, result)
    } catch (err) {
      console.error(`Updating records failed. Error: ${err}`)
    }
  }

  return summary
}

function filterUpdatedEvents(context, evt) {
  if (evt.dataValues?.length >= 1) {
    if (context.recordType === RecordType.RI) return true

    // Blank COVID enrollment events usually have DoseNumber entries, an event
    // is considered modified if there are other dataValues besides DoseNumber
    if (evt.dataValues?.length > 1) return true

    // Accept events with single entry that is not DoseNumber
    return evt.dataValues[0].dataElement !== DataElementID.COVID.DoseNumber
  }

  return false
}

export function createRecords(context, records) {
  const trackedEntityInstances = records
    .map((r) => {
      const record = r.toEntity().toEntityJSON(context.programId, context.auth.ou)

      // add orgUnit if missing
      record.orgUnit = record.orgUnit || context.auth.ou

      // clean out empty events that only have dose number info
      record.enrollments[0].events = record.enrollments[0].events
        .filter((e) => filterUpdatedEvents(context, e))
        .map((e) => {
          // set common fields on event
          e.deleted = false

          // FIXME: status dropped for RI in order for events to be editable/deletable during dev
          e.status = context.recordType === RecordType.COVID ? 'COMPLETED' : undefined
          e.orgUnit = context.auth.ou

          // set team details
          const userTeamOption = store.getters.getUserTeamOption
          e.attributeOptionCombo = TeamUserGroupID[userTeamOption]
          e.attributeCategoryOptions = userTeamOption
          return e
        })

      return record
    })

  const config = { headers: getBasicAuthHeaders(context.auth.token) }
  return postTrackedEntity({ trackedEntityInstances }, config)
    .then((response) => processImportSummaries(records, response.data.response.importSummaries))
    .catch((err) => {
      const data = err.response.data
      if ('response' in data) {
        return processImportSummaries(records, err.response.data.response.importSummaries)
      }
      return { failed: records.map((r) => ({ vaccinationNumber: r.profile.vaccinationNumber })) }
    })
}

export async function updateRecords(context, records) {
  let summary = { created: [], updated: [], failed: [] }
  const config = { headers: getBasicAuthHeaders(context.auth.token) }

  for (const record of records) {
    const { attributes, events } = getUpdatedProductPartsEntityJSON(context, record)
    if (record.modified) {
      const results = await updateTrackedEntity(record, attributes, config)
      summary = merge(summary, results)
    }

    if (events.new) {
      const results = await createEnrollmentEvents(record, events.new, config)
      summary = merge(summary, results)
    }

    if (events.old) {
      const results = await updateEnrollmentEvents(record, events.old, config)
      summary = merge(summary, results)
    }
  }

  return summary
}

function getUpdatedProductPartsEntityJSON(context, record) {
  const { profile, verification, vaccinations, enrollment } = record
  const attributes = profile.toDataObject().toEntityJSON()

  const enrollmentEvents = context.recordType === RecordType.COVID
    ? [verification, ...vaccinations.map((v) => v.data)]
    : [enrollment.birthData, ...enrollment.vaccinations]

  const events = enrollmentEvents
    .filter((e) => e.modified)
    .map((e) => {
      const event = e.toEntity().toEntityJSON()

      // set common fields on event
      event.deleted = false

      // FIXME: status dropped for RI in order for events to be editable/deletable during dev
      event.status = context.recordType === RecordType.COVID ? 'COMPLETED' : undefined
      event.orgUnit = record.enrollment?.orgUnit || context.auth.ou
      event.program = record.enrollment?.program || context.programId
      event.enrollment = record.enrollment?.enrollment?.enrollment
      event.enrollmentStatus = record.enrollment?.enrollmentStatus
      event.trackedEntityInstance = record.entity?.trackedEntityInstance

      // set team details
      const userTeamOption = store.getters.getUserTeamOption
      event.attributeOptionCombo = TeamUserGroupID[userTeamOption]
      event.attributeCategoryOptions = userTeamOption
      return event
    })

  return {
    attributes,
    events: groupBy(events, (e) => !e.event ? 'new' : 'old')
  }
}

async function createEnrollmentEvents(record, events, config) {
  let summary = { created: [], failed: [] }
  const records = Array(events.length).fill(record)
  try {
    const response = (await postEnrollmentEvent({ events }, config)).data.response
    const result = processImportSummaries(records, response.importSummaries)
    summary = merge(summary, result)
  } catch (err) {
    console.error(err)
    const result = processImportSummaries(records, err.response.data.response.importSummaries)
    summary = merge(summary, result)
  }

  return {
    created: summary.created.slice(0, 1),
    failed: summary.failed.slice(0, 1)
  }
}

async function updateTrackedEntity(record, attributes, config) {
  const summary = { updated: [], failed: [] }
  const { attributes: _, enrollment: __, ...fields } = record.entity
  const vaccinationNumber = record.profile.vaccinationNumber

  try {
    const entity = { ...fields, attributes }
    await putTrackedEntity(entity, config)
    summary.updated.push(vaccinationNumber)
  } catch (err) {
    summary.failed.push({
      vaccinationNumber,
      conflicts: err.response.data.conflicts
    })
  }
  return summary
}

async function updateEnrollmentEvents(record, events, config) {
  const summary = { updated: [], failed: [] }
  const vaccinationNumber = record.profile.vaccinationNumber

  for (const event of events) {
    try {
      await putEnrollmentEvent(event, config)
      summary.updated.push(vaccinationNumber)
    } catch (err) {
      summary.failed.push({
        vaccinationNumber,
        conflicts: err.response.data.conflicts
      })
    }
  }

  return {
    updated: summary.updated.slice(0, 1),
    failed: summary.failed.slice(0, 1)
  }
}

function processImportSummaries(records, summaries) {
  const result = { created: [], updated: [], failed: [] }
  for (let index = 0; index < Math.min(records.length, summaries.length); index++) {
    const record = records[index]
    const summary = summaries[index]

    switch (summary.status) {
      case 'SUCCESS':
        if (summary.importCount.imported > 0) {
          result.created.push(record.profile.vaccinationNumber)
        } else {
          result.updated.push(record.profile.vaccinationNumber)
        }
        break
      case 'ERROR':
        result.failed.push({
          vaccinationNumber: record.profile.vaccinationNumber,
          conflicts: summary.conflicts
        })
        break
    }
  }

  return result
}
